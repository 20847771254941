<script setup lang="ts">
import { useStore } from '@/store';
import { Btn, Image, UploadInput } from '@bagelink/vue';
import { watch } from 'vue';

defineProps<{ uploadIcon: 'image' | 'certificate' }>();

const store = useStore();
const files = defineModel<string[]>('modelValue', { default: () => [] as string[] });

function startUpload() {
	store.isUploading = true;
}
watch(files, () => (store.isUploading = false));
</script>

<template>
	<UploadInput :dirPath="store.submitID" v-model="files" accept="image/*,.pdf" multiple @addFileStart="startUpload">
		<template #files="{ files: pathKeys }">
			<div
				v-for="(pKey, idx) in pathKeys"
				:key="pKey"
				v-lightbox="{ group: 'images', pathKey: pKey }"
				class="previewName"
			>
				<Image height="60" class="preview" :alt="pKey" :pathKey="pKey" />
				<p class="act_no-margin">
					{{ pKey.split('/').pop() }}
				</p>
				<button class="del-img hover" @click.stop="files.splice(idx, 1)">
					<img src="@/assets/delete.svg" alt="delete" />
				</button>
			</div>
		</template>
		<template #placeholder="{ files }">
			<template v-if="!files.length && uploadIcon === 'image'">
				<img class="p-025" src="@/assets/Wizard-images.svg" alt="images" />
				<p class="m-025 txt-16 balance">
					{{ $t('sell.addingImages') }}
				</p>
			</template>
			<template v-else-if="!files.length">
				<img class="p-025" src="@/assets/Wizard-certificate.svg" alt="certificate" />
				<p class="m-075 txt-16 balance">
					{{ $t('sell.certificate') }}
				</p>
			</template>
			<Btn class="pointer-events-none" icon="add" />
		</template>
	</UploadInput>
</template>
