<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import type { Locale } from '@/lang'
import { languages, setLang, setLangURL } from '@/lang'
import { Flag } from '@bagelink/vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const emit = defineEmits(['opennavbar'])

const route = useRoute()

const $i18n = useI18n()

// eslint-disable-next-line prefer-const
let openLangList = _ref(false)

const selecteLang = _computed(() => languages.find(l => l.locale === $i18n.locale.value.slice(0, 2).toLowerCase()))
</script>

<template>
	<div class="topnav">
		<LocalLink to="/" class="logo-wrap hover decoration-none">
			<img class="logo " width="160" height="35.27" src="@/assets/logo.png" alt="AUCTENTIC">
			<p class="mobile">
				{{ $t('home.title') }}
			</p>
		</LocalLink>
		<LocalLink class="act_nav-link desktop" to="/how-does-it-work" :title="$t('home.howItWorks')">
			{{ $t("home.howItWorks") }}
		</LocalLink>

		<div v-click-outside="() => openLangList = false" class="lang-picker in-nav">
			<div class="lang hover flex gap-05" @click="openLangList = !openLangList">
				<Flag :country="(selecteLang?.flag as any)" />
				{{ selecteLang?.locale }}
			</div>
			<div class="lang-list" :class="{ openLangList }">
				<RouterLink
					v-for="lang in languages" :key="lang.locale" :to="setLangURL(route.path, lang.locale as Locale)"
					class="lang hover decoration-none flex gap-05" @click="setLang(lang.locale)"
				>
					<Flag :country="(lang.flag as any)" />
					{{ lang.locale }}
				</RouterLink>
			</div>
			<img
				width="8" height="28" class="hover" src="@/assets/down-arrow.svg" alt="down"
				@click="openLangList = !openLangList"
			>
		</div>
		<LocalLink class="small-top-nav-btn hover" to="/get-the-best-offer" :title="$t('cta.startHere')">
			{{ $t('cta.startHere') }}
		</LocalLink>
		<div class="menu-btn" @click="emit('opennavbar', true)">
			<img width="23" height="18" src="@/assets/menu-blue.svg" alt="menu">
		</div>
	</div>
</template>

<style>
.topnav {
  max-width: 1170px;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 1rem;
}

.LocalLink-active.LocalLink-exact-active.menu-link {
  color: var(--blue);
}

.logo-wrap {
  font-size: 1.01ch;
  line-height: 2.6;
  margin-inline-end: auto;
}

.logo-wrap p {
  white-space: nowrap;
}

.act_nav-link {
  text-transform: uppercase;
  text-decoration: none;
  transition: all 400ms ease;
}

.act_nav-link:hover {
  color: var(--blue);
}

.act_nav-link:active {
  filter: brightness(90%);
}

.lang-picker {
  display: flex;
  gap: 0.25rem;
  position: relative;
  user-select: none;
  line-height: 5.5vh;
  padding-bottom: 0.5rem;
}

.lang-picker.in-nav {
  padding-bottom: 0rem;
  opacity: 1;
}

.lang-list {
  position: absolute;
  background: var(--blue-dark);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  width: 80px;
  color: white;
  top: 0px;
  margin-inline-start: -10px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  opacity: 0;
}

.lang-picker.in-nav .lang-list {
  background: white;
  color: #070707;
}

.lang-list.openLangList {
  opacity: 1;
  top: 30px;
  pointer-events: all;
  z-index: 99999;
}

.lang {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 400ms ease;
  padding: 0.5rem;
  border-radius: 10px;
  line-height: 1;
}

.lang-picker.in-nav .lang {
  width: 55px;
  padding: 0.5rem 0;
}

.lang.activeLang {
  background: var(--blue-dark);
  color: white;
}

.lang img {
  width: 20px;
  margin-inline-end: 0.25rem;
  margin-top: -0.25rem;
}

.menu-btn {
  transition: all 400ms ease;
  user-select: none;
  cursor: pointer;
  filter: saturate(0) brightness(200%);
  line-height: 0;
}

.menu-btn:hover {
  filter: saturate(100%) brightness(100%);
}

.menu {
  position: fixed;
  pointer-events: none;
  background: white;
  border-end-start-radius: 20px;
  border-start-start-radius: 20px;
  padding: 2rem;
  inset-inline-end: 0;
  height: 96vh;
  top: 2vh;
  transition: all 400ms ease;
  transform: translateX(450px);
  width: 450px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;
  visibility: hidden;
}

.menu.opennavbar {
  pointer-events: all;
  transform: translateX(0) !important;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.12));
  visibility: visible;
}

.close-menu {
  position: absolute;
  top: 2rem;
  inset-inline-end: 2rem;
  transition: all 400ms ease;
  cursor: pointer;
  filter: saturate(100%) brightness(100%);
}

.close-menu:hover {
  filter: saturate(0) brightness(100%);
}

.close-menu:active {
  filter: saturate(0) brightness(80%);
}

.menu-link {
  display: block;
  text-decoration: none;
  line-height: 5vh;
  transition: none;
  opacity: 0;
}

.opennavbar .menu-link {
  opacity: 1;
  /* transition: all 500ms ease-in; */
  transition: opacity 500ms ease-in, color 2ms ease-in;

}

.menu-link:nth-child(2) {
  transition-delay: 0.1s, 1ms;
}

.menu-link:nth-child(3) {
  transition-delay: 0.2s, 1ms;
}

.menu-link:nth-child(4) {
  transition-delay: 0.3s, 1ms;
}

.menu-link:nth-child(5) {
  transition-delay: 0.4s, 1ms;
}

.menu-link:nth-child(6) {
  transition-delay: 0.5s, 1ms;
}

.menu-link:nth-child(7) {
  transition-delay: 0.6s, 1ms;
}

.menu-link:nth-child(8) {
  transition-delay: 0.7s, 1ms;
}

.menu-link:nth-child(9) {
  transition-delay: 0.8s, 1ms;
}

.menu-link:nth-child(10) {
  transition-delay: 0.9s, 1ms;
}

.menu-link:nth-child(11) {
  transition-delay: 1s, 1ms;
}

.menu-link:nth-child(12) {
  transition-delay: 1.1s, 1ms;
}

.lang-picker {
  opacity: 0;
  transition: all 500ms ease-in;
}

.opennavbar .lang-picker {
  opacity: 1;
  transition-delay: 0.1s;
}

.menu-link:hover {
  color: var(--blue);
}

.menu-link:active {
  filter: brightness(90%);
}

.menu-soc {
  max-height: 31px;
  width: 31px;
  transition: all 400ms ease;
}

.lang-picker.in-menu {
  display: none;
}

@media screen and (max-width: 900px) {
  .lang-picker.in-nav {
    display: none !important;
  }

  .lang-picker.in-menu {
    display: flex;
  }

  .menu-btn {
    margin-top: 0.25rem;
  }

  .topnav {
    align-items: flex-start;
    padding: 1.5rem 1rem;
    margin-bottom: 0rem;
    gap: 1rem;

  }

  .logo-wrap {
    margin: 0;
    width: 130px;
    font-size: 0.82ch;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu {
    border-end-start-radius: 0;
    border-start-start-radius: 0;
    inset-inline-end: 0;
    height: 100svh;
    top: 0vh;
    transform: translateX(-105%) !important;
    width: 100%;
  }
}
@media screen and (max-height: 900px) {
.menu-link {
  line-height: 2;
}
}
</style>
