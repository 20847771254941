<script setup lang="ts">import { computed as _computed } from 'vue';

import type { ItemType } from '@/api/types'

const props = defineProps<{ item?: ItemType, details: Record<string, any> }>()
function image(type?: ItemType) {
	if (type === 'Gemstone') return '/img/Wizard-Gemstones.svg'
	if (type === 'Diamond') return '/img/Wizard-Diamonds.svg'
	if (type === 'Watch') return '/img/Wizard-Watches.svg'
	if (type === 'Brooch') return '/img/Wizard-Brooch.svg'
	if (type === 'Earring') return '/img/Wizard-Earrings.svg'
	if (type === 'Bracelet') return '/img/Wizard-Bracelets-2.svg'
	if (type === 'Necklace') return '/img/Wizard-Necklaces.svg'
	if (type === 'Ring') return '/img/Wizard-Rings.svg'
	if (type === 'Multiple Items') return '/img/Wizard-Multiple-Items.svg'
	return ''
}

const relevantFields = [
	'carat_range',
	'carat',
	'color',
	'clarity',
	'shape',
	'watch_brand',
	'certificate_exists',
	'watch_box',
	'number_of_diamonds',
	'gemstone_type',
	'certificate_type',
	'brand_name',
	'brand'
]

const keys = _computed(() => Object.keys(props.details).filter(key => relevantFields.includes(key)))
</script>

<template>
	<div class="act_center w-100 justify-content-center act_flex act_pb-1">
		<div class="sum-box act_round" :class="{ 'multi-item': item === 'Multiple Items' }">
			<div class="act_flex align-center act_gap-1 act_pb-1">
				<img v-if="image(item)" width="30" :src="image(item)" :alt="item">
				<!-- <p>{{ keyToLabel(item) }}</p> -->
				<p>{{ $t(`${item}`) }}</p>
			</div>
			<div v-if="Object.keys(details).length" class="act_flex act_gap-1 act_start wrap m_act_gap-025">
				<p v-for="key in keys" :key="key" class="">
					{{ $t(`sell.${key}`) }}:
					<!-- <b>{{ keyToLabelWithHypen(details[key]) }}</b> -->
					<b>{{ $t(`${details[key]}`) }}</b>
				</p>
			</div>
		</div>
	</div>
</template>

<style>
.sum-box {
	width: 100%;
	padding: 1rem 2rem;
	background: #ECF1F6;
}

.wiz-options-wrap .sum-box {
	background: var(--white-light);
}

.multi-item img{
	width: 50px;
	height: 50px;
}

.multi-item .act_pb-1 {
	padding-bottom: 0;
}
</style>
