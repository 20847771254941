<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { tp } from '@/data/trustPilot'
import { timeAgo } from '@/utils/timeAgo'
import { Carousel } from '@bagelink/vue'
import { useI18n } from 'vue-i18n'

const showFullReviews = _ref(false)
const { locale } = useI18n()

const trustpilotData = _computed(() => {
	const LocalBusiness = tp.find((item: any) => item['@type'] === 'LocalBusiness')
	return LocalBusiness?.aggregateRating
})
const auctenticURL = 'https://www.trustpilot.com/review/auctentic.com/'
function computeURL(schemaURL: string) {
	const clientID = schemaURL.split('auctentic.com/')[1]
	return auctenticURL + clientID
}
const latestReviews = _computed(() => tp.filter((item: any) => item['@type'] === 'Review'))
const itReviews = _computed(() => latestReviews.value.filter((item: any) => item.inLanguage.match('it')))
const locReviews = _computed(() => [...latestReviews.value.filter((item: any) => item.inLanguage.match(locale.value)), ...itReviews.value])
</script>

<template>
	<div class="act_center act_pt-3 act_pb-4">
		<a
			title="Read more on trustpilot" href="https://www.trustpilot.com/review/auctentic.com" target="_blank"
			class="act_txt40 flex-desktop act_gap-1 align-center justify-content-center"
		>
			<p>Excellent {{ trustpilotData?.ratingValue }}</p>
			<img width="200" height="40" src="@/assets/trust-stars.svg" alt="trustpilot stars">
		</a>
		<a
			href="https://www.trustpilot.com/review/auctentic.com" target="_blank" :title="$t('home.readOurReviews')"
			class="act_txt24 act_flex act_gap-1 align-center justify-content-center act_pt-1"
		>
			<p>{{ trustpilotData?.reviewCount }} reviews on</p>
			<img width="135" height="36" src="@/assets/trust-logo-white.svg" alt="trustpilot">
		</a>
		<div class="reviews-wrap act_w1170 act_pt-3" :class="{ showFullReviews }">
			<Carousel
				:items="3"
			>
				<a
					v-for="review in locReviews" :key="review['@id']" :href="computeURL(review['@id'])"
					target="_blank" class="review-box act_start white-box relative "
				>
					<img width="120" src="@/assets/trust-stars.svg" alt="Stars">
					<p v-if="review.datePublished" class="review-time absolute act_txt14">
						{{ timeAgo(review.datePublished) }}
					</p>
					<p class="act_pb-1 act_txt14 act_pt-025">
						{{ review.author?.name }}
					</p>
					<p><b>{{ review.headline }}</b></p>
					<p class="review-body">
						{{ review.reviewBody }}
					</p>
				</a>
				<template #next>
					<div class="hover">
						<img width="24" height="24" src="@/assets/arrow-right.svg" alt="Next">
					</div>
				</template>

				<template #prev>
					<div class="hover">
						<img width="24" height="24" src="@/assets/arrow-left.svg" alt="Back">
					</div>
				</template>
			</Carousel>
			<a class="act_btn act_mt-2" href="https://www.trustpilot.com/review/auctentic.com" target="_blank">{{ $t('cta.seeMore')
			}}</a>
			<!-- <button class="act_btn act_mt-2" @click="showFullReviews = true" :class="{ showFullReviews }">
				{{ $t('cta.seeMore') }}
			</button> -->
		</div>
	</div>
</template>

<style>
.reviews-wrap .Handlers{
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}
.reviews-wrap .Handlers div{
height: 40px;
width: 40px;
border-radius: 100%;
background: var(--blue);
display: flex;
padding: 0.5rem;
}
</style>

<style scoped>
.reviews {
  max-height: 700px;
  overflow: hidden;
  position: relative;
  transition: all 2000ms ease;
}

.reviews::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  /* background: linear-gradient(rgba(255, 255, 255, 0), var(--blue-dark) 100%); */
  transition: all 2000ms ease;

}

.showFullReviews .reviews {
  max-height: 2000vh;
}

.showFullReviews .reviews::after {
  height: 0;
}

.showFullReviews .act_btn {
  opacity: 0;
  pointer-events: none;
}

a {
  text-decoration: none;
}

.review-box {
  padding: 1.8rem !important;
  padding-top: 1.6rem !important;
  border-radius: 10px;
  margin: 0.5rem auto;
  /* break-inside: avoid-column; */
}

.review-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.review-time {
  top: 1rem;
  inset-inline-end: 1rem;
  opacity: 0.7;
}

.review-body {
  max-height: 243px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 900px) {
  .review-wrap {
    display: grid;
    grid-template-columns: unset;
    position: relative;
    scroll-behavior: smooth;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    align-items: start;
    margin-left: -5%;
    margin-right: -5%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .review-wrap::-webkit-scrollbar {
    /* display: none !important; */
    /* opacity: 0; */
  }

  .review-body {
    height: auto;

  }

  .review-box {
    scroll-snap-align: start;
    height: 356px
  }

  .reviews {
    max-height: unset;
    overflow: unset;
  }

  .reviews::after {
    background: transparent;

  }

}
</style>
