import type { AxiosResponse } from 'axios'
import type { FormSubmissionBase, FormSubmissionPayload } from './types'
import { getBglParams } from '@/formUtils'
import { axios } from './api'

export const bglForm = {
	put: async (
		formId: string,
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmissionBase>> => {
		const bglParams = getBglParams()
		return axios.put(`/form/${formId}`, { ...formSubmissionPayload, ...bglParams })
	},
	postByFormName: async (
		formName: string,
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmissionBase>> => {
		const bglParams = getBglParams()
		return axios.post(`/form/${formName}`, { ...formSubmissionPayload, ...bglParams })
	},
	post: async (
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmissionBase>> => {
		const bglParams = getBglParams()
		return axios.post('/form', { ...formSubmissionPayload, ...bglParams })
	},
}
