<script setup lang="ts">import { ref as _ref } from 'vue';

import * as blockComponents from '@/blockBuilder/Blocks'
import { blocks } from '@/blockBuilder/blocksConfig'
import BotFooter from '@/components/BotFooter.vue'
import TopNav from '@/components/TopNav.vue'
import { sellPageBlocks } from '@/data/sellPageBlocks'
import { langFromPath, setLang } from '@/lang'
import { Btn, Loading } from '@bagelink/vue'
import { onMounted, } from 'vue'
import { useRoute } from 'vue-router'

let highlightID = _ref('')
interface ComponentData {
	id: string
	type: keyof typeof blockComponents
	data: {
		title?: string
		[key: string]: any
	}
}

let sellPage = _ref<{ components: ComponentData[], language: Record<string, any> }>({
	language: { value: '' },
	components: [],
})

function postMessage(type: string, message: any) {
	window.parent.postMessage({ type, message }, '*')
}

function emitFocus(id: string) {
	postMessage('focus', id)
}

function emitHighlight(id: string) {
	postMessage('highlight', id)
}

function setHighlight(id: string, emit = false) {
	highlightID.value = id
	if (emit) emitHighlight(id)
}

function setFocus(id: string, emit = false) {
	highlightID.value = id
	const el = document.querySelector(`[data-index="${id}"]`)
	el?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
	if (emit) emitFocus(id)
}

let loading = _ref(true)

function startListner() {
	window.addEventListener('message', (event) => {
		const { type, message } = event.data
		if (type === 'update' && message.data) {
			sellPage.value.components = message.data
		} else if (type === 'highlight') {
			setHighlight(message)
		} else if (type === 'focus') {
			setFocus(message)
		}
		loading.value = false
	})
	postMessage('ready', blocks)
}

const route = useRoute()

onMounted(() => {
	const pageLang = langFromPath(route.path)
	const page = sellPageBlocks.find((page: any) => page.slug === route.params.sell_slug && page.language?.value === pageLang) as any
	sellPage.value = page || { components: [], language: { value: pageLang } }
	if (sellPage.value.language.value) setLang(sellPage.value.language.value)
	startListner()
})
</script>

<template>
	<div class="act_bg-white">
		<TopNav />
		<div class="page-wrap act_w1170">
			<div v-if="loading" class="flex flex-center min-h-600px">
				<Loading size="100" :thickness="3" />
			</div>
			<div v-else-if="!sellPage?.components.length" class="txt-center">
				<p class="py-3 txt-40 balance">
					Ummm... Nothing here
				</p>
				<div class="pb-2">
					<img class=" opacity-5" width="300px" src="./empty2.svg" alt="empty">
				</div>
				<Btn round value="Add a new Block" />
			</div>
			<template v-for="(comp, _i) in sellPage.components" :key="comp.id">
				<div
					class="relative" :class="{ highlight: highlightID === comp?.id }" :data-index="comp.id"
					@click="setFocus(comp.id, true)" @mouseenter="setHighlight(comp.id, true)"
					@focusin="setHighlight(comp.id)"
				>
					<p class="compName position-start -top-1 txt-10 px-05 color-white">
						{{ comp.type }}
						<span v-if="comp?.data?.title" class="color-black">
							|
						</span>

						{{ comp.data.title }}
					</p>
					<component :is="blockComponents[comp.type]" v-bind="comp.data" />
					<div class="coverComp" />
				</div>
			</template>
		</div>
		<BotFooter />
	</div>
</template>

<style>
.act_bg-white .top-nav-wrapper {
	background-color: var(--blue-dark);
}

.coverComp {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.act_bg-white {
	background: white;
}

.act_bg-white .page-wrap {
	color: #0b2335;
}

.act_bg-white .home-carousel-item {
	border: 1px solid #b7b7b7;
}

.highlight,
.editMode:hover {
	animation: highlighter 1.5s ease-in-out forwards;
	outline: 1px solid #58bfeb;
	background: #58bfeb20;
}

.compName {
	opacity: 0;
	background: #58bfeb;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;

}

.highlight .compName {
	opacity: 1 !important;
}

@keyframes highlighter {
	to {
		/* outline: 1px solid #58bfeb00; */
		background: #58bfeb00;

	}
}
</style>
