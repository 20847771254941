<script setup lang="ts">import { ref as _ref } from 'vue';

const continueActive = _ref(false)
</script>

<template>
	<div
		class="continue-process white-box act_flex act_gap-1 justify-content-center align-center balance hover"
		:class="{ continueActive }"
	>
		<img src="@/assets/close-black.svg" alt="close" @click="continueActive = false">
		<LocalLink
			:title="$t('home.continueTheSubmissionProcess')" to="/get-the-best-offer"
			class="decoration-none act_flex act_gap-1 justify-content-center align-center"
		>
			<img width="56" height="43" src="@/assets/Wizard-Diamonds.svg" alt="Diamond">
			<p>
				{{ $t("home.continueTheSubmissionProcess") }}
			</p>
			<img src="@/assets/arrow-right-blue.svg" alt="arrow right">
		</LocalLink>
	</div>
</template>

<style scoped>
.continue-process {
  padding: 1.25rem;
  width: fit-content;
  position: fixed;
  bottom: 5%;
  inset-inline-end: 5%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  z-index: 99;
  transform: translateX(100vw);
  transition-duration: 0.6s;
  max-width: 90%;
}

.continueActive {
  transform: translateX(0);
}
</style>
