<script setup lang="ts">import { computed as _computed } from 'vue';

import { testimonials } from '@/data/testimonials'
import { Carousel } from '@bagelink/vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
const locTestimonials = _computed(() => testimonials.filter(t => t.language.value === locale.value))
</script>

<template>
	<div class="act_center act_w1170 act_pt-3 act_pb-4">
		<div class="testimonial-card">
			<h2 class="playfair act_txt60 act_no-marg act_pb-3">
				{{ $t('home.customerReviews') }}
			</h2>
			<Carousel :items="1" :autoplay="true">
				<div v-for="t in locTestimonials" :key="t._id">
					<div class="grid testimonial-grid act_w700 act_start act_pb-4">
						<div class="relative testimonial-profile-wrap">
							<img
								class="testimonial-profile" width="100" height="100" :src="t.image.imageURL" :alt="t.name"
								loading="lazy"
							>
							<img
								v-if="t.video" v-lightbox="t.video" class="play hover absolute" width="40" height="40"
								src="@/assets/Play.svg" alt="Play"
							>
						</div>
						<div>
							<p class="playfair act_txt24">
								{{ t.review }}
							</p>
							<div class="testimobial-name flex-desktop act_gap-1 act_pt-1">
								<p>{{ t.name }}</p>
								<div class="act_flex act_gap-05">
									<img v-for="i in t.starRating" :key="i" width="26" height="26" src="@/assets/star.svg" alt="star">
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--
				<template #next>
					<div>
						<img width="24" height="24" src="@/assets/arrow-right-black.svg" alt="Next">
					</div>
				</template>

				<template #prev>
					<div>
						<img width="24" height="24" src="@/assets/arrow-left-black.svg" alt="Back">
					</div>
				</template>
				-->
			</Carousel>
		</div>
	</div>
</template>

<style>
.testimonial-card .Handlers {
  display: flex;
  opacity: 0.6;
  margin: 0rem auto -3.5rem;
  background: white;
  border-radius: 30px;
  width: 110px;
  padding: 0.5rem;
  gap: 1rem;
  justify-content: center;
}

.testimonial-card .Handlers img {
  cursor: pointer;
  transition: all 400ms ease;
}

.testimonial-card .Handlers img:hover {
  opacity: 0.6;
}

.testimonial-card .Handlers img:hover {
  opacity: 1;
}

.testimonial-profile {
  border-radius: 100%;
  object-fit: cover;
}

.testimonial-card {
  border-radius: 30px;
  background: linear-gradient(180deg, #255771 0%, rgba(37, 87, 113, 0.16) 100%);
  width: 100%;
  padding: 2rem;
}

.testimonial-grid {
  grid-template-columns: 135px 1fr;
  padding-inline-start: 2rem;
}

.testimonial-profile-wrap {
  margin: 0 auto;
  height: 100px;
}

.play {
  bottom: 0rem;
  inset-inline-end: 0rem;
  position: absolute;
}

.video .modal {
  padding: 1rem;
}

.video iframe {
  aspect-ratio: 16/9;
  max-width: 1100px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: -0.5rem;
}

@media screen and (max-width: 900px) {
  .testimonial-grid {
    grid-template-columns: 1fr;
    padding-inline-start: 0rem;
    gap: 1rem;
    text-align: center;
    justify-content: center;
  }

  .testimobial-name {
    padding-top: 2rem;
  }

  .testimobial-name .act_flex {
    justify-content: center;
  }
}
</style>
