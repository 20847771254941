import type { BglFormSchemaT } from '@bagelink/vue'
import { bagelFormUtils } from '@bagelink/vue'
// import VueTelInput from '@/components/VueTelInput/VueTelInput.vue'
const { txtField, frmRow } = bagelFormUtils
import { $t } from '@/lang'
import { telField } from './sellForm'

export const vipFormSchema: BglFormSchemaT = [
	frmRow(
		txtField('first_name', $t('general.name'), { required: true }),
		txtField('last_name', $t('general.surname'), { required: true }),
	),
	txtField('email', $t('general.email'), { required: true, type: 'email' }),
	telField('phone', $t('general.phone'), { required: true }),
	txtField('comment', $t('general.message'), { multiline: true }),
]
