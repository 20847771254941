import { i18n } from './lang'
import { getSessionStorage, setSessionStorage } from './utils'

export function initBglForm() {
	const searchParams = new URLSearchParams(window.location.search)
	const params = {
		query_params: Object.fromEntries(searchParams),
		referrer: document.referrer,
		landing_page: window.location.href
	}
	setSessionStorage('bgl_params', params)
}

function loadCookieData() {
	const cookie_data = Object.fromEntries(
		document.cookie.split(';').map(cookie => cookie.split('=').map(part => part.trim()))
	)
	return cookie_data
}

export function getBglParams() {
	let params = getSessionStorage('bgl_params')
	const cookie_data = loadCookieData()

	console.log(cookie_data)
	params = params || {}
	return {
		...params,
		page_url: window.location.href,
		language: i18n.locale.value,
		hs_language: i18n.locale.value,
		cookie_data,
	}
}
