<script setup lang="ts">

</script>

<template>
	<div class="relative how-home  act_pt-4 act_pb-4">
		<div class="act_w900">
			<h2 class="playfair act_txt60 act_center act_no-marg act_pb-1">
				{{ $t('home.howItWorks') }}

				<!-- <span class="act_blue">:</span> -->
			</h2>
			<!-- <p class="act_blue txt20 act_center">
				{{ $t('home.auctenticWillHelpYou') }}
			</p> -->
			<div class="timeline">
				<div class="timeline-row">
					<div class="timeline-icon-wrap">
						<img
							loading="lazy" width="134" height="88" src="@/assets/How-it-works-icon-1.svg"
							alt="Fill a simple online form"
						>
					</div>
					<div class="timeline-dot-wrap">
						<div class="timeline-dot" />
					</div>
					<div class="timeline-txt-wrap">
						<h3 class="playfair act_txt30 act_no-marg act_pb-025">
							{{ $t('home.fillASimpleOnlineForm') }}
						</h3>
						<p class="act_pb-05">
							{{ $t('home.fewMinutes') }}
						</p>
						<LocalLink to="/get-the-best-offer" :title="$t('home.goToForm')">
							{{ $t('home.goToForm') }}
						</LocalLink>
					</div>
				</div>
				<div class="timeline-row">
					<div class="timeline-icon-wrap">
						<img loading="lazy" width="141" height="89" src="@/assets/How-it-works-icon-2.svg" alt="Get a valuation">
					</div>
					<div class="timeline-dot-wrap">
						<div class="timeline-dot" />
					</div>
					<div class="timeline-txt-wrap">
						<h3 class="playfair act_txt30 act_no-marg act_pb-025">
							{{ $t('home.getAValuation') }}
						</h3>
						<p class="act_pb-05">
							{{ $t('home.dedicatedSpecialist') }}
						</p>
					</div>
				</div>
				<div class="timeline-row">
					<div class="timeline-icon-wrap">
						<img loading="lazy" width="130" height="91" src="@/assets/How-it-works-icon-3.svg" alt="Schedule Shipment">
					</div>
					<div class="timeline-dot-wrap">
						<div class="timeline-dot" />
					</div>
					<div class="timeline-txt-wrap">
						<h3 class="playfair act_txt30 act_no-marg act_pb-025">
							{{ $t('home.scheduleShipment') }}
						</h3>
						<p class="act_pb-05">
							{{ $t('home.secureAFullyInsured') }}
						</p>
						<LocalLink :title="$t('home.readMore')" to="/how-does-it-work#EasyandSecureShipping">
							{{ $t('home.readMore') }}
						</LocalLink>
					</div>
				</div>
				<div class="timeline-row">
					<div class="timeline-icon-wrap">
						<img
							loading="lazy" width="109" height="110" src="@/assets/How-it-works-icon-4.svg"
							alt="Get the best offer"
						>
					</div>
					<div class="timeline-dot-wrap">
						<div class="timeline-dot" />
					</div>
					<div class="timeline-txt-wrap">
						<h3 class="playfair act_txt30 act_no-marg act_pb-025">
							{{ $t('home.getTheBestOffer') }}
						</h3>
						<p class="act_pb-05">
							{{ $t('home.theMomentWeCanCheck') }}
						</p>
					</div>
				</div>
				<div class="timeline-row">
					<div class="timeline-icon-wrap">
						<img
							loading="lazy" width="132" height="116" src="@/assets/How-it-works-icon-5.svg"
							alt="Get paid in 24 hours"
						>
					</div>
					<div class="timeline-dot-wrap">
						<div class="timeline-dot" />
					</div>
					<div class="timeline-txt-wrap">
						<h3 class="playfair act_txt30 act_no-marg act_pb-025">
							{{ $t('home.getPaidIn_24Hours') }}
						</h3>
						<p class="act_pb-05">
							{{ $t('home.onceYouApproveTheOffer') }}
						</p>
					</div>
				</div>
				<div class="timeline-line" />
			</div>
			<div class="act_center">
				<LocalLink :title="$t('home.getStarted')" class="act_btn wide-btn" to="/get-the-best-offer">
					{{ $t('home.getStarted') }}
				</LocalLink>
			</div>
		</div>
		<div class="act_oval side-oval" />
	</div>
</template>

<style>
.timeline-icon-wrap {
  width: 360px;
  text-align: end;
}

.timeline-dot-wrap {
  flex: 0 1 150px;
  display: flex;
  justify-content: center;
}

.timeline-txt-wrap {
  width: 360px;
}

.timeline {
  display: grid;
  gap: 6rem;
  position: relative;
  margin: 4rem 0;
}

.timeline-row {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.timeline-row:nth-child(even) {
  flex-direction: row-reverse;
}

.timeline-row:nth-child(even) .timeline-icon-wrap {
  text-align: start;
}

.timeline-dot {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: white;
  outline: 20px solid rgba(255, 255, 255, 0.2);
  z-index: 2;
  background: linear-gradient(180deg, var(--blue) 50%, white 50.1%);
  background-attachment: fixed;
}

.timeline-dot.act_blue {
  background: var(--blue);
}

.timeline-line {
  height: calc(100% - 100px);
  position: absolute;
  width: 2px;
  background: linear-gradient(180deg, var(--blue) 50%, white 50.1%);
  background-attachment: fixed;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}

.how-home {
  overflow: hidden;

}

.side-oval {
  position: absolute;
  inset-inline-start: -30%;
  bottom: 30%;
  top: auto;
  z-index: -1;
  transform: scale(1.4);
}

@media screen and (max-width: 900px) {
  .timeline-icon-wrap {
    width: 100%;
    text-align: start;
  }

  .timeline-icon-wrap img {
    height: 60px;
    width: 100px;
    text-align: start
  }

  .timeline-txt-wrap {
    width: 100%;
  }

  .timeline {
    gap: 3rem;
    margin: 2rem auto;
    max-width: 400px;
    width: 90%;
  }

  .timeline-row {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-inline-start: 3rem;
  }

  .timeline-row:nth-child(even) {
    flex-direction: column;
  }

  .timeline-dot {
    width: 20px;
    height: 20px;
    outline: 10px solid rgba(255, 255, 255, 0.2);
  }

  .timeline-dot-wrap {
    flex: 0 1 0px;
    display: flex;
    justify-content: center;
    position: absolute;
    inset-inline-start: 0;
    top: 85px;
  }

  .timeline-line {
    height: calc(100% - 85px);
    inset-inline-start: 10px;
    inset-inline-end: auto;
    margin: unset;
  }
}
</style>
