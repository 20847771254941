export const it = {
	form: {
		whatIsTheBrandOfYourJewelry: 'Di che marca è il tuo gioiello?',
		continueTitle: 'Continuare?',
		pendingSubmission: 'Hai una presentazione in sospeso. Vuoi continuare o ricominciare?',
		restart: 'Ricomincia',
		continue: 'Continua',
		description: 'Descrivi i tuoi articoli',
		doYouHaveMoreThanOneItem: 'Hai più di un oggetto?',
		numberOfDiamonds: 'Numero di diamanti',
		caratWeight: 'Peso in carati',
		personalDetails: 'Dettagli personali',
		notsureother: 'Non sicuro / Altro',
		Round: 'Tondo',
		Princess: 'Princess',
		Cushion: 'Cuscino',
		Marquise: 'Marquise',
		Emerald: 'Smeraldo',
		Oval: 'Ovale',
		Pear: 'Pera',
		Heart: 'Cuore',
		Radiant: 'Radiant',
		doYouHaveDocuments: 'Hai i documenti?',
		whatIsTheBrandOfYourBracelet: 'Di che brand è il tuo bracciale?',
		howManyDiamondsInYourNecklace: 'Quanti diamanti ci sono nella tua collana?',
		howManyDiamondsInYourBracelet: 'Quanti diamanti sono montati sul tuo bracciale?',
		optional: 'Opzionale',
		weReAlmostThere: 'Ci siamo quasi',
		enterYourInfoAndSeeHowMuchYouCanGet: 'Inserisci le informazioni necessarie per scoprire quanto puoi ottenere.',
		firstName: 'Nome',
		lastName: 'Cognome',
		email: 'Email',
		pleaseEnterAValidEmail: 'Inserisci un indirizzo email valido',
		invalidPhoneNumber: 'Numero di telefono non valido',
		phoneNumber: 'Numero di telefono',
		iAgreeToThe: 'Accetto i',
		termsAndConditions: 'termini e condizioni',
		excellentAreThereAnyPhotosThatYouCouldShareWithUs: 'Ottimo! Ci sono delle foto che potresti condividere con noi?',
		no: 'No',
		yes: 'Sì',
		describeYourDiamond: 'Descrivi il tuo diamante',
		inAFewWordsDescribeYourJewelry: 'Descrivi brevemente i tuoi gioielli',
		typeAnythingYouWouldLikeUsToKnow: 'Scrivi tutto ciò che vuoi farci sapere',
		uploadJewelryPhotos: 'Carica foto dei gioielli',
		enterTheLargestDiamondSCaratWeight: 'Inserisci il peso in carati del diamante più grande',
		whatIsTheCaratWeightOfTheDiamond: 'Qual è il peso in carati del diamante?',
		countTheNumberOfDiamondsOnTheJewlAndEnterBelow: 'Conta il numero di diamanti sul gioiello e inseriscilo qui sotto',
		whatIsTheTotalNumberOfDiamondsInTheJewl: 'Qual è il numero totale di diamanti nel gioiello?',
		totalCaratWeightJewl: 'Inserisci il peso totale in carati di tutti i diamanti del tuo gioiello',
		whatIsTheCaratWeightOfTheDiamonds: 'Qual è il peso in carati dei diamanti?',
		enterHere: 'Inserisci qui',
		whatIsTheBrandOfYourWatch: 'Di che marca è il tuo orologio?',
		other: 'Altro',
		doYouHaveTheOriginalBox: 'Hai la scatola originale?',
		whatIsTheCaratWeightOfYourDiamond: 'Qual è il peso in carati del tuo diamante?',
		whatIsTheShapeOfYourDiamond: 'Di che forma è il tuo diamante?',
		excellentWhatIsTheColorOfYourDiamond: 'Perfetto! Sai quale è il colore del tuo diamante?',
		whatIsTheClarityOfYourDiamond: 'Conosci la purezza del tuo diamante?',
		greatDoYouHaveACertificateForYourDiamondIfSoWhatKind: 'Fantastico! Hai un certificato per il tuo diamante? Se sì, di che tipo è?',
		whatIsTheTypeOfYourGemstone: 'Di che pietra preziosa si tratta?',
		sapphire: 'Zaffiro',
		whatIsTheCaratWeightOfYourGemstone: 'Qual è il peso in carati della tua pietra preziosa?',
		whatIsTheShapeOfYourGemstone: "Com'è tagliata la tua pietra preziosa?",
		selectAnOption: "Seleziona un'opzione",
		doYouHaveACertificate: 'Hai un certificato?',
		notSure: 'Non sono sicuro',
		notSureOther: 'Non sono sicuro / Altro',
		brooch: 'Spilla',
		earring: 'Orecchini',
		ring: 'Anello',
		bracelet: 'Bracciale',
		necklace: 'Collana',
		watch: 'Orologio',
		diamond: 'Diamante',
		gemstone: 'Pietra preziosa',
		ruby: 'Rubino',
		radiant: 'Radiant',
		heart: 'Cuore',
		pear: 'Goccia',
		oval: 'Ovale',
		emerald: 'Smeraldo',
		marquise: 'Marquise',
		cushion: 'Cuscino',
		princess: 'Princess',
		round: 'Rotondo',
		addACertificateIfYouHaveOne: 'Carica il certificato se in tuo possesso',
		addImages: 'Carica delle foto',
		diamondCertificateUpload: 'Se hai una foto o il certificato del diamante, per favore caricali qui',
		certificate: 'Certificato',
		WhatShapeOfLargestDiamond: 'Di che forma è il diamante principale?',
	},
	Other: 'Altro',
	'Multiple Items': 'Articoli Multipli',
	'false': 'falso',
	'not sure': 'Non sicuro',
	Sapphire: 'Zaffiro',
	Round: 'Rotondo',
	Princess: 'Princess',
	Cushion: 'Cuscino',
	Marquise: 'Marquise',
	Emerald: 'Smeraldo',
	Oval: 'Ovale',
	Pear: 'Goccia',
	Heart: 'Cuore',
	Radiant: 'Radiant',
	Ruby: 'Rubino',
	Gemstone: 'Pietra preziosa',
	Diamond: 'Diamante',
	Watch: 'Orologio',
	Necklace: 'Collana',
	Bracelet: 'Bracciale',
	Ring: 'Anello',
	Earring: 'Orecchini',
	Brooch: 'Spilla',
	sell: {
		'Multiple Items': 'Articoli Multipli',
		carat_range: 'Carato',
		brand: 'Marca',
		brand_name: 'Marca',
		optional: 'Opzionale',
		certificate_type: 'Tipo di certificato',
		gemstone_type: 'Tipo di pietra preziosa',
		number_of_diamonds: 'Numero di diamanti',
		watch_box: "Scatola dell'orologio",
		certificate_exists: 'Certificato esistente',
		watch_brand: 'Marca di orologio',
		skip: 'Salta',
		carat: 'Carato',
		ruler: 'Quando fotografi il tuo oggetto posizionalo vicino a un righello, come riferimento in scala. Scegli una superficie chiara e una zona ben illuminata, meglio se vicino a una finestra. Evita il flash per mantenere la profondità e avvicinati fisicamente per ottenere nitidezza. Tocca lo schermo per mettere a fuoco e sperimenta le angolazioni per ottenere lo scatto ideale per mettere in risalto al meglio il tuo gioiello o la tua pietra preziosa.',
		priceRange: 'Fascia di Prezzo:',
		forCustomers: 'Se il tuo diamante è di oltre 5 carati, contattaci direttamente cliccando qui',
		color: 'Colore',
		clarity: 'Purezza',
		weight: 'Peso',
		shape: 'Forma',
		clickToLearn: 'Clicca per alcuni suggerimenti su come scattare al meglio le foto',
		unsureHow: 'Se non sai come fare buone foto',
		certificate: 'Se hai un certificato dei tuoi beni preziosi, caricalo qui',
		addingImages: 'Aggiungere immagini consente di avere una stima più precisa. Fotografa i tuoi gioielli accanto a un righello, in luce naturale',
		pleaseInsertYourPhotosHere: 'Carica qui le tue foto',
		selectTheTypeOfJewelleryYouHave: 'Seleziona che tipo di gioiello hai',
		discoverTheValueOfYourJewels: 'Scopri il Valore dei Tuoi Gioielli',
		submit: 'Invia',
		next: 'Successivo',
		return: 'Indietro',
	},
	recentlySold: {
		clearAll: 'Cancella tutto',
		seeMore: 'Altro',
		subtitle: 'Scopri gli ultimi articoli che abbiamo venduto per i nostri clienti',
		title: 'Venduti di recente',
	},
	faq: {
		saleProcess: 'Processo di Vendita',
		calculator: 'Calcolatore',
		gettingPaid: 'Pagamento',
		grading: 'Classificazione',
		serviceCosts: 'Costi del Servizio',
		insurance: 'Assicurazione',
		shipping: 'Spedizione',
		pricing: 'Valutazione',
		general: 'Generale',
	},
	thankYou: {
		goToDashboard: 'Vai alla Dashboard',
		backHome: 'Torna alla Home',
		paragraph: 'Ti contatteremo presto',
		title: 'Grazie per la tua fiducia',
	},
	contactUs: {
		goToDashboard: 'Vai alla Dashboard',
		touch: 'Rimarremo in contatto',
		thankYou: 'Grazie!',
		error: "Si è verificato un errore durante l'invio",
		writeUsAMessage: 'Lasciaci un messaggio',
		netherlands: 'Olanda',
		belgium: 'Belgio',
		spain: 'Spagna',
		france: 'Francia',
		italy: 'Italia',
		phoneEmailWhatsApp: 'Telefono Email & WhatsApp',
		bookAMeeting: 'Fissa una videochiamata',
		bookACall: 'Prenota una chiamata con i nostri esperti per ottenere maggiori informazioni',
		scheduleAMeeting: 'Fissa un Appuntamento',
		weAlwaysLove: 'Siamo sempre a disposizione dei nostri clienti, per garantire sempre il miglior servizio nel modo più adatto ad ogni situazione',
		contactUs: 'Contattaci',
	},
	general: {
		signUp: 'Iscriviti',
		events: 'Eventi',
		ourStory: 'La nostra storia',
		backEvent: 'Torna agli eventi futuri',
		signUpInput: 'Quali gioielli sei interessato a vendere?*',
		eventsSubtitle: 'Un elenco di eventi Auctentic dove puoi ottenere una stima e vendere i tuoi prodotti',
		eventsTitle: 'Prossimi eventi',
		selling: 'Vendita',
		getTheLatest: "Ricevi le ultime notizie su gioielli, diamanti e l'industria",
		ourServices: 'I nostri servizi',
		message: 'Messaggio',
		phone: 'Telefono',
		email: 'Email',
		surname: 'Cognome',
		name: 'Nome',
		send: 'Invia',
		login: 'Accedi',
		howToTakeQualityPhotos: 'Come scattare foto di qualità',
		whySellWithUs: 'Perché vendere con noi',
		youMayAlsoLike: 'Potrebbe anche interessarti',
		tableOfContents: 'Indice dei Contenuti',
		blog: 'Blog',
		backHome: 'Torna alla Home',
		somethingEvenBetter: 'ma abbiamo qualcosa di ancora migliore:',
		notExist: 'Questa pagina non esiste,',
		oops: 'Ops! Non riusciamo a trovare questa pagina',
	},
	unqualified: {
		title: 'Non possiamo vendere il tuo articolo',
		paragraph: `Purtroppo, dai dati inseriti risulta che non siamo il canale adatto per fornire una valorizzazione attualizzata del diamante proposto. Le caratteristiche indicate non soddisfano infatti i criteri richiesti dal mercato di acquirenti con il quale ci interfacciamo.
Per qualsiasi domanda, non esitare a contattarci.`,
		questions: 'Se hai domande, non esitare a contattarci.',
		differentItem: 'Vendere un oggetto diverso',
		whatWeSell: 'cosa puoi vendere',
		trust: 'Grazie per la tua fiducia',
	},
	disclaimer: {
		html: '<p>Le stime fornite si basano su certificati GIA per diamanti di:</p><p>Taglio: Molto buono o Eccellente</p><p>Simmetria: Molto buona o Eccellente</p><p>Fluorescenza: Da nessuna a media</p><br><p>I valori effettivi possono variare.</p><p>Per valutazioni puntuali, consultare i nostri gemmologi certificati.</p>',
		precise: 'Per valutazioni puntuali, consultare i nostri gemmologi certificati.',
		actual: 'I valori effettivi possono variare.',
		fluorescence: 'Fluorescenza: Da nessuna a media',
		symmetry: 'Simmetria: Molto buona o Eccellente',
		cut: 'Taglio: Molto buono o Eccellente',
		estimates: 'Le stime fornite si basano su certificati GIA per diamanti di:',
		disclaimer: 'Avvertenza',
	},
	home: {
		guaranteedPayment: 'Pagamento garantito e immediato',
		eval: 'Valuta i tuoi gioielli',
		findBuyers: 'Trova potenziali acquirenti',
		negotiate: "Negozia l'offerta migliore per te",
		readOurReviews: 'Leggi le nostre recensioni',
		moreAboutShipping: 'Más sobre el envío',
		soldFor: 'Venduto a',
		weTravelAcrossEurope: 'Possiamo spostarci in tutta Europa per valutare i tuoi gioielli, garantendoti sempre comodità e privacy.',
		onSiteEvaluation: 'Valutazione in loco',
		receiveHighQualityDiamonds: "Ottieni in modo semplice per i tuoi gioielli e diamanti di maggior valore una certificazione internazionale che ne confermi ufficialmente l'interesse e le caratteristiche",
		premiumCertification: 'Certificazione Premium',
		benefitFromPersonalized: "Usufruisci di un'attenzione personalizzata e dell'assistenza di un consulente dedicato che supervisioni i tuoi articoli.",
		dedicatedConsultant: 'Consulente Dedicato',
		elevateYourExperience: 'Migliora la tua esperienza con i nostri servizi su misura per gioielli di alta gamma:',
		vipClients: 'Alta Gioielleria',
		getStarted: 'Iniziamo?',
		onceYouApproveTheOffer: "Una volta accettata l'offerta, il pagamento verrà immediatamente accreditato sul tuo conto. Semplicemente.",
		getPaidIn_24Hours: 'Ricevi il pagamento in 24 ore',
		theMomentWeCanCheck: 'Non appena avremo verificato e confermato agli acquirenti le caratteristiche del tuo oggetto, riceveremo le offerte dai potenziali interessati e ti comunicheremo la migliore disponibile.',
		getTheBestOffer: 'Ottieni la migliore offerta',
		readMore: 'Leggi di Più',
		secureAFullyInsured: "Forniamo un servizio di spedizione assicurato, rapido e tracciato, per ritirare l'articolo da casa tua o dal tuo ufficio, nel giorno da te scelto.",
		scheduleShipment: 'Pianifica la Spedizione',
		dedicatedSpecialist: 'Uno specialista gemmologo del nostro team, a te dedicato, ti contatterà con una stima preliminare per i tuoi articoli e ti guiderà attraverso tutti i passaggi successivi.',
		getAValuation: 'Ottieni una valutazione',
		goToForm: 'Vai al Modulo',
		fewMinutes: 'Tutto ciò di cui hai bisogno sono pochi minuti per caricare le immagini dei tuoi gioielli (e la certificazione, se disponibile) e compilare il modulo interattivo. Noi ci occuperemo di tutto il resto.',
		fillASimpleOnlineForm: 'Compila un semplice modulo online',
		auctenticWillHelpYou: 'Auctentic ti aiuterà a ottenere la migliore offerta in modo rapido, semplice e sicuro, in 5 passi:',
		howItWorks: 'Come Funziona',
		continueTheSubmissionProcess: 'Continua a completare form',
		transformYourHighValueJewels: "Trasforma i tuoi gioielli di alto valore in un'esperienza di vendita straordinaria. Completa il modulo qui sotto e sblocchiamo insieme il loro pieno potenziale.",
		vipCustomers: 'Alta Gioielleria',
		scheduleYourAppointment: 'Richiedi il tuo appuntamento',
		customerReviews: 'Recensioni dei Clienti',
		paymentMethods: 'Approfitta dei nostri metodi di pagamento sicuri e immediati.',
		safeTransactions: 'Transazioni Sicure',
		potentialBuyers: 'Rilassati mentre proponiamo i tuoi gioielli ai potenziali acquirenti.',
		idealBuyers: 'Individuare gli acquirenti ideali',
		timelyDeliveries: 'Gestiamo consegne sicure e tempestive.',
		secureShipment: 'Spedizione Sicura',
		leaveTheIntricateDealingsToUs: 'Lascia a noi le trattative complesse.',
		expertNegotiation: 'Negoziazione condotta da Esperti',
		weDonTChargeYouADime: 'Non ci sono costi nascosti: la nostra commissione è a carico del solo acquirente.',
		noSellerFees: 'Nessuna commissione di vendita',
		networkEncompasses: 'La nostra rete comprende oltre 2.000 acquirenti internazionali, permettendoci di assicurare la migliore offerta per i tuoi gioielli.',
		globalReach: 'Portata Globale',
		specializedGemologist: 'I nostri gemmologi specializzati si assicurano che tu ottenga il vero valore del tuo prezioso.',
		expertAssessment: 'Valutazione di un Esperto',
		FAQ: 'FAQ',
		withOurOnlineDiamondCalculator: 'Con il nostro Diamond Calculator',
		valuateYourDiamond: 'Valuta il Tuo Diamante',
		sold: 'Venduto',
		recentlySold: 'Venduti di Recente',
		security: 'Più Sicurezza',
		convenience: 'Più Comodità',
		value: 'Più Valore',
		getMore: 'I Nostri Servizi',
		sloganline2: ' Senza Vincolo alla Vendita',
		slogan: 'Ottieni la Migliore Offerta,',
		title: 'Vendi Gioielli e Diamanti Online',
	},
	productTypes: {
		Watches: 'Orologi',
		Earrings: 'Orecchini',
		Bracelets: 'Braccialetti',
		Necklaces: 'Collane',
		Diamonds: 'Diamanti',
		Rings: 'Anelli',
		All: 'Tutto',
	},
	footer: {
		sellOnline: 'Vendere on-line',
		howToPack: 'Come imballare i tuoi gioielli',
		watches: 'Orologi',
		jewellery: 'Gioielli',
		diamonds: 'Diamanti',
		gemstones: 'Pietre Preziose',
		evaluateYourDiamond: 'Valuta il Tuo Diamante',
		sellSafely: 'Vendi in Sicurezza',
		cookiePolicy: 'Cookie Policy',
		privacyPolicy: 'Informativa privacy',
		howToTakePhotos: 'Come scattare le foto',
		faQs: 'FAQs',
		aboutUs: 'Chi Siamo',
		transparencyAndTrust: 'Trasparenza e Fiducia',
		whatWeAccept: 'Cosa puoi vendere',
		contactUs: 'Contattaci',
		blog: 'Blog',
		whySellWithUs: 'Perché vendere con noi',
		howItWorks: 'Come funziona',
		general: 'Generale',
		franceOfficeAd: '19, Boulevard Malesherbes 75008 Parigi',
		franceOffice: 'Sede Francese',
		italyOfficeAd: 'Via Albricci, 9 - 20122 Milano - Tel. +39 02 49751300',
		italyOffice: 'Sede Italiana',
		belgiumOfficeAd: `Presso la Borsa dei Diamanti di Anversa
Hoveniersstraat 2, bus 550 Ufficio 109 - 2018 Anversa`,
		belgiumOffice: 'Sede Belga',
		amsterdamOfficeAd: 'Poortland 66 - 1046 BD Amsterdam VAT: NL863728042B01',
		amsterdamOffice: 'Sede Olandese',
	},
	photos: {
		ruler: 'Quando fotografi il tuo oggetto posizionalo vicino a un righello, come riferimento in scala. Scegli una superficie chiara e una zona ben illuminata, meglio se vicino a una finestra. Evita il flash per mantenere la profondità e avvicinati fisicamente per ottenere nitidezza. Tocca lo schermo per mettere a fuoco e sperimenta le angolazioni per ottenere lo scatto ideale per mettere in risalto al meglio il tuo gioiello o la tua pietra preziosa.',
		severalPhotos: 'Scatta diverse foto: per ottenere buone immagini spesso devi vari scatti. Prova diversi angoli, luci e impostazioni per trovare le foto perfette che metteranno in risalto il tuo gioiello.',
		focus: "Metti a fuoco: Assicurati che la foto del tuo gioiello risulti nitida toccando lo schermo del tuo smartphone per mettere a fuoco l'area desiderata.",
		getCloser: "Avvicinati: Lo zoom spesso comporta una perdita di qualità dell'immagine. Se puoi avvicinati fisicamente al gioiello o usa un obiettivo macro per scatti dettagliati.",
		notUseFlash: "Non usare il flash: il flash può appiattire l'immagine e nascondere dettagli importanti. Scegli se puoi la luce naturale per dare profondità e realismo ai tuoi gioielli.",
		naturalLight: 'Usa luce naturale: la luce serale o degli interni di solito non è sufficiente per ottenere immagini chiare; opta per  ambienti diurni ben illuminati. Per i migliori risultati, scatta in luce morbida e diffusa vicino a una finestra. Evita la luce solare diretta, poiché può creare ombre innaturali o eccessive.',
		polishYourGems: 'Pulisci i tuoi gioielli prima di scattare una foto: assicurati che siano privi di polvere, pelucchi, graffi, impronte digitali o qualsiasi materiale estraneo che possa apparire nella foto.',
		simpleTips: 'Ecco alcuni semplici consigli e trucchi che ti aiuteranno a ottenere foto di alta qualità usando solo il tuo smartphone.',
		beautifulPhotos: 'Scattare belle foto dei tuoi gioielli non è facile come sembra; ci vuole pazienza e pratica per ottenere lo scatto perfetto.',
		qualityPhotos: 'Come scattare belle foto dei tuoi gioielli',
	},
	howWorks: {
		quickandSafePayment: {
			body: "<p>Una volta accettata l'offerta, riceverai il pagamento immediatamente tramite bonifico bancario. La nostra principale priorità è garantire che tu venga pagato rapidamente e facilmente. Nel caso in cui decidessi invece di tenere il tuo oggetto, non preoccuparti: prepareremo una spedizione di ritorno assicurata e gratuita all'indirizzo da te indicato, che riceverai entro 48 ore lavorative.</p>",
			title: 'Pagamento Rapido e Sicuro',
		},
		getthebestoffer: {
			body: '<p>Dopo aver preso in consegna i tuoi gioielli, li prepareremo per la vendita pulendoli, classificandoli e fotografandoli per attirare potenziali acquirenti. Mostreremo le tue proprietà a oltre 2.000 acquirenti professionisti nella nostra rete e ti riporteremo la migliore offerta tra quelle ricevute.</p>',
			title: 'Ottieni la Migliore Offerta',
		},
		easyandSecureShipping: {
			body: `<p>Scegli il momento più comodo per inviarci i tuoi articoli e noi ci occuperemo di tutto il resto. La nostra massima priorità è garantire il ritiro sicuro e tempestivo dei tuoi articoli, con una copertura assicurativa completa garantita. Ci impegniamo a rendere la tua esperienza il più facile possibile occupandoci della sicurezza dei tuoi beni preziosi a 360°.</p>
			<br>
			<a title="Malca Amit" href="https://www.malca-amit.com/" target="_blank">
				<img src="/malca-amit.svg" alt="malca-amit">
				</a>
			`,
			title: 'Spedizione Facile e Sicura',
		},
		expertValuation: {
			body: "<p>Le informazioni che fornisci serviranno come base per i nostri gemmologi esperti per valutare le caratteristiche del tuo gioiello. La valutazione iniziale, condotta tramite analisi fotografica, ci consente di stimare un primo valore assicurativo dell'oggetto. Questo viene fatto per proteggere i preziosi durante il trasporto. Potremmo contattarti per ulteriori dettagli. Saremo sempre a disposizione e ti guideremo ad ogni passo.</p>",
			title: 'Valutazione di un Esperto',
		},
		ourJourneyBegins: {
			body: '<p>Compila il nostro semplice modulo online per iniziare. È facile; basta caricare foto e dettagli dei tuoi beni preziosi, insieme a eventuali certificati che potresti avere. Il nostro team di esperti esaminerà le informazioni che hai fornito e risponderemo anche a eventuali domande che potresti avere. Iniziamo!</p>',
			title: 'Cominciamo!',
		},
		subTitle: 'Come riusciamo ad aiutarti ad ottenere il miglior prezzo in modo rapido, semplice e sicuro:',
		howItWorks: 'Come Funziona',
	},
	whySell: {
		noStringsAttachedParagraph: 'Nessun vincolo, mai. Puoi sempre decidere di interrompere il processo di vendita, in qualsiasi fase, per qualsiasi motivo, senza alcun obbligo da parte tua.',
		noStringsAttached: 'Nessun Vincolo',
		saferAndQuickerParagraph: 'Ogni articolo inviato o consegnato personalmente ai nostri uffici viene accuratamente valutato dai nostri gemmologi specialisti, senza costi di spedizione o valutazione. Ci rivolgiamo solo ad acquirenti selezionati che esprimono una chiara preferenza per i tipi specifici di pietre preziose e gioielli che sei interessato a vendere.',
		saferAndQuicker: 'Più Sicuro e Veloce',
		personalServiceParagraph: "Avrai a disposizione uno specialista personale dedicato per accompagnarti durante tutto il processo, dall'inizio alla fine, che ti terrà informato in tempo reale di qualsiasi sviluppo, ad ogni passaggio.",
		personalService: 'Servizio Personalizzato',
		whySellWithUs: 'Perché Vendere Con Noi?',
		interestParagraph: "Il nostro modello finanziario si basa sulla percentuale di intermediazione addebitata solo all'acquirente, il che significa che condividiamo lo stesso interesse di te: ottenere il miglior prezzo che il mercato può offrire. Non ci sono costi aggiuntivi o commissioni nascoste per te.",
		yourInterestIsOurInterest: 'Il tuo interesse è il nostro interesse',
	},
	whatWeAccept: {
		whatWeAccept: 'Cosa puoi vendere',
	},
	about: {
		FAQ: 'FAQ',
		theTeam: 'Il Team',
		with_20Years: 'Con oltre 20 anni di esperienza nei diamanti, Auctentic è il tuo consulente ideale per la gioielleria. Ti mettiamo a disposizione una rete di 2.000 acquirenti fidati, seguendo le tue vendite con trasparenza, professionalità e rispetto. Benvenuto in Auctentic, dove ogni gemma trova la sua storia.',
		aboutUs: 'Chi Siamo',
		ourOffices: 'I nostri uffici',
	},
	cta: {
		seeMore: 'Recensioni',
		sellYourNecklace: 'Vendi la Tua Collana',
		sellYourGemstone: 'Vendi la Tua Pietra Preziosa',
		sellYourWatch: 'Vendi il Tuo Orologio',
		sellYourRing: 'Vendi il Tuo Anello',
		sellYourEarrings: 'Vendi i Tuoi Orecchini',
		sellYourDiamond: 'Vendi il Tuo Diamante',
		sellYourBracelet: 'Vendi il Tuo Braccialetto',
		getTheBestOffer: 'Ottieni la Migliore Offerta',
		paragraph: 'Vivi la nostra esperienza di servizio personalizzato per rendere il tuo viaggio semplice e gratificante.',
		startHere: 'Comincia qui',
		getStarted: 'Iniziamo',
	},
};
