export const fr = {
	form: {
		whatIsTheBrandOfYourJewelry: 'Quelle est la marque de votre bijou ?',
		continueTitle: 'Continuer?',
		pendingSubmission: 'Vous avez une soumission en attente. Voulez-vous continuer ou redémarrer?',
		restart: 'Redémarrer',
		continue: 'Continuer',
		description: 'Veuillez décrire vos articles',
		doYouHaveMoreThanOneItem: "Avez-vous plus d'un article?",
		numberOfDiamonds: 'Nombre de diamants',
		caratWeight: 'Poids en carats',
		personalDetails: 'Détails personnels',
		notsureother: 'Pas sûr / Autre',
		Round: 'Rond',
		Princess: 'Princesse',
		Cushion: 'Coussin',
		Marquise: 'Marquise',
		Emerald: 'Émeraude',
		Oval: 'Ovale',
		Pear: 'Poire',
		Heart: 'Cœur',
		Radiant: 'Radiant',
		doYouHaveDocuments: 'Possédez-vous les papiers?',
		whatIsTheBrandOfYourBracelet: 'Quelle est la marque de votre bracelet ?',
		howManyDiamondsInYourNecklace: 'Quel est le nombre total de diamants dans votre collier?',
		howManyDiamondsInYourBracelet: 'Combien de diamants sont-ils sertis dans votre bracelet?',
		optional: 'Optionnel',
		weReAlmostThere: 'Nous y sommes presque',
		enterYourInfoAndSeeHowMuchYouCanGet: 'Saisissez les informations nécessaires et découvrez combien pouvez vous obtenir.',
		firstName: 'Prénom',
		lastName: 'Nom de famille',
		email: 'Email',
		pleaseEnterAValidEmail: 'Veuillez saisir une adresse e-mail valide',
		invalidPhoneNumber: 'Numéro de téléphone incorrect',
		phoneNumber: 'Numéro de téléphone',
		iAgreeToThe: "Je suis d'accord avec les",
		termsAndConditions: 'termes et conditions',
		excellentAreThereAnyPhotosThatYouCouldShareWithUs: 'Excellent ! Y a-t-il des photos que vous pourriez partager avec nous ?',
		no: 'Non',
		yes: 'Oui',
		describeYourDiamond: 'Décrivez votre diamant',
		inAFewWordsDescribeYourJewelry: 'Décrivez vos bijoux en quelques mots ',
		typeAnythingYouWouldLikeUsToKnow: 'Tapez tout ce que vous aimeriez que nous sachions',
		uploadJewelryPhotos: 'Téléchargez des photos des bijoux',
		enterTheLargestDiamondSCaratWeight: 'Saisissez le poids en carats du plus grand diamant',
		whatIsTheCaratWeightOfTheDiamond: 'Quel est le poids en carats du diamant ?',
		countTheNumberOfDiamondsOnTheJewlAndEnterBelow: 'Comptez le nombre de diamants sur le bijou et entrez-le ci-dessous',
		whatIsTheTotalNumberOfDiamondsInTheJewl: 'Quel est le nombre total de diamants dans le bijou ?',
		totalCaratWeightJewl: 'Entrez le poids total en carats de tous les diamants de votre bijou',
		whatIsTheCaratWeightOfTheDiamonds: 'Quel est le poids en carats des diamants ?',
		enterHere: 'Entrez ici',
		whatIsTheBrandOfYourWatch: 'Quelle est la marque de votre montre ?',
		other: 'Autre',
		doYouHaveTheOriginalBox: "Avez-vous la boîte d'origine ?",
		whatIsTheCaratWeightOfYourDiamond: 'Quel est le poids en carats de votre diamant ?',
		whatIsTheShapeOfYourDiamond: 'Quelle est la forme de votre diamant ?',
		excellentWhatIsTheColorOfYourDiamond: 'Excellent ! Quelle est la couleur de votre diamant ?',
		whatIsTheClarityOfYourDiamond: 'Quelle est la pureté de votre diamant ?',
		greatDoYouHaveACertificateForYourDiamondIfSoWhatKind: "Super ! Possédez-vous un certificat pour votre diamant ? Si oui, de quel type s'agit-il ?",
		whatIsTheTypeOfYourGemstone: "C'est quelle pierre précieuse ?",
		sapphire: 'Saphir',
		whatIsTheCaratWeightOfYourGemstone: 'Quel est le poids en carats de votre pierre précieuse ?',
		whatIsTheShapeOfYourGemstone: 'Quelle est la taille de votre pierre précieuse ?',
		selectAnOption: 'Sélectionnez une option',
		doYouHaveACertificate: 'Possédez-vous un certificat ?',
		notSure: 'je ne suis pas sûr',
		notSureOther: 'Je ne suis pas sûr / Autre',
		brooch: 'Broche',
		earring: "Boucles d'oreille",
		ring: 'Bague',
		bracelet: 'Bracelet',
		necklace: 'Collier',
		watch: 'Montre',
		diamond: 'Diamant',
		gemstone: 'Pierre précieuse',
		ruby: 'Rubis',
		radiant: 'Radiant',
		heart: 'Cœur',
		pear: 'Poire',
		oval: 'Ovale',
		emerald: 'Émeraude',
		marquise: 'Marquise',
		cushion: 'Coussin',
		princess: 'Princesse',
		round: 'Rond',
		addACertificateIfYouHaveOne: 'Téléchargez le certificat si vous en avez un',
		addImages: 'Téléchargez des photos',
		diamondCertificateUpload: 'Si vous avez une photo ou le certificat du diamant, veuillez les télécharger ici',
		certificate: 'Certificat',
		WhatShapeOfLargestDiamond: 'Quelle est la taille du diamant principale ?',
	},
	Other: 'Autre',
	'Multiple Items': 'Articles Multiples',
	'false': 'faux',
	'not sure': 'Pas sûr',
	Sapphire: 'Saphir',
	Round: 'Rond',
	Princess: 'Princesse',
	Cushion: 'Coussin',
	Marquise: 'Marquise',
	Emerald: 'Émeraude',
	Oval: 'Ovale',
	Pear: 'Poire',
	Heart: 'Cœur',
	Radiant: 'Radiant',
	Ruby: 'Rubis',
	Gemstone: 'Pierre précieuse',
	Diamond: 'Diamant',
	Watch: 'Montre',
	Necklace: 'Collier',
	Bracelet: 'Bracelet',
	Ring: 'Bague',
	Earring: "Boucles d'oreille",
	Brooch: 'Broche',
	sell: {
		'Multiple Items': 'Articles Multiples',
		carat_range: 'Carat',
		brand: 'Marque',
		brand_name: 'Marque',
		optional: 'Optionnel',
		certificate_type: 'Type de certificat',
		gemstone_type: 'Type de pierre précieuse',
		number_of_diamonds: 'Nombre de diamants',
		watch_box: 'Boîte de montre',
		certificate_exists: 'Certificat existant',
		watch_brand: 'Marque de montre',
		skip: 'Sauter',
		carat: 'Carat',
		ruler: "Lorsque vous photographiez votre objet, placez-le à côté d'une règle pour obtenir une échelle de référence. Choisissez une surface claire et un endroit bien éclairé, de préférence près d'une fenêtre. Évitez le flash pour conserver la profondeur et rapprochez-vous physiquement pour assurer la netteté. Touchez l'écran pour faire la mise au point et expérimentez les angles de prise de vue pour obtenir la photo idéale qui mettra le mieux en valeur votre bijou ou votre pierre précieuse.",
		priceRange: 'Gamme de Prix :',
		forCustomers: 'Si le poids de votre diamant dépasse les 5 carats, veuillez nous contacter directement en cliquant ici',
		color: 'Couleur',
		clarity: 'Clarté',
		weight: 'Poids',
		shape: 'Forme',
		clickToLearn: 'Cliquez pour apprendre comment prendre de meilleures photos',
		unsureHow: 'Si vous ne savez pas comment prendre de bonnes photos',
		certificate: 'Si vous avez un certificat pour vos objets de valeur, veuillez le télécharger ici',
		addingImages: "Ajouter des images permet une estimation plus précise. Photographiez vos bijoux à côté d'une règle à la lumière naturelle",
		pleaseInsertYourPhotosHere: 'Veuillez télécharger vos photos ici',
		selectTheTypeOfJewelleryYouHave: 'Sélectionnez le Type de Bijou que Vous Avez',
		discoverTheValueOfYourJewels: 'Découvrez la Valeur de Vos Bijoux',
		submit: 'Soumettre',
		next: 'Suivant',
		return: 'Précédent',
	},
	recentlySold: {
		clearAll: 'Tout effacer',
		seeMore: 'Altri',
		subtitle: 'Découvrez les derniers articles que nous avons vendus pour nos clients',
		title: 'Vendu Récemment',
	},
	faq: {
		saleProcess: 'Processus de Vente',
		calculator: 'Simulateur',
		gettingPaid: 'Paiement',
		grading: 'Évaluation',
		serviceCosts: 'Coûts de service',
		insurance: 'Assurance',
		shipping: 'Expédition',
		pricing: 'Évaluation ',
		general: 'Général',
	},
	thankYou: {
		goToDashboard: 'Aller au Tableau de bord',
		backHome: "Retour à l'Accueil",
		paragraph: 'Nous vous contacterons bientôt',
		title: 'Merci pour votre confiance',
	},
	contactUs: {
		goToDashboard: 'Aller au Tableau de bord',
		touch: 'Nous resterons en contact',
		thankYou: 'Merci !',
		error: "Une erreur s'est produite lors de l'envoi",
		writeUsAMessage: 'Laissez-nous un message',
		netherlands: 'Pays-Bas',
		belgium: 'Belgique',
		spain: 'Espagne',
		france: 'France',
		italy: 'Italie',
		phoneEmailWhatsApp: 'Téléphone Email & WhatsApp',
		bookAMeeting: `Prenez rendez-vous
pour une visio`,
		bookACall: "Prenez rendez-vous pour un appel avec nos experts afin d'obtenir plus d'informations",
		scheduleAMeeting: 'Prenez Rendez-vous',
		weAlwaysLove: 'Nous sommes toujours à disposition de nos clients et nous mettons tout en œuvre pour offrir le meilleur service de la manière qui vous convient le mieux.',
		contactUs: 'Contactez-nous',
	},
	general: {
		signUp: 'Inscription',
		events: 'Événements',
		ourStory: 'Notre histoire',
		backEvent: 'Retour aux événements à venir',
		signUpInput: 'Quels bijoux êtes-vous intéressé à vendre ?*',
		eventsSubtitle: 'Une liste des événements Auctentic où vous pouvez obtenir une estimation et vendre vos produits',
		eventsTitle: 'Événements à venir',
		selling: 'Vente',
		getTheLatest: "Restez informé des dernières nouvelles sur les bijoux, les diamants et l'industrie",
		ourServices: 'Nos services',
		message: 'Message',
		phone: 'Téléphone',
		email: 'E-mail',
		surname: 'Nom de Famille',
		name: 'Prénom',
		send: 'Soumettre',
		login: 'Se connecter',
		howToTakeQualityPhotos: 'Comment prendre des photos de qualité',
		whySellWithUs: 'Pourquoi vendre avec nous',
		youMayAlsoLike: 'Vous pourriez aussi aimer',
		tableOfContents: 'Table des Matières',
		blog: 'Blog',
		backHome: "Retour à l'Accueil",
		somethingEvenBetter: 'mais nous avons quelque chose de encore mieux :',
		notExist: "Cette page n'existe pas,",
		oops: 'Oups ! Nous ne pouvons pas trouver cette page',
	},
	unqualified: {
		title: 'Nous ne pouvons pas vendre votre article',
		paragraph: `Malheureusement, il ressort des données saisies que nous ne sommes pas le canal approprié pour fournir une évaluation actualisée du diamant proposé. En effet, les caractéristiques indiquées ne correspondent pas aux critères requis par le marché d'acheteurs avec lequel nous sommes en relation.
Si vous avez des questions, n'hésitez pas à nous contacter.`,
		questions: "Si vous avez des questions, n'hésitez pas à nous contacter.",
		differentItem: 'Vendre un autre article',
		whatWeSell: 'Ce que vous pouvez vendre',
		trust: 'Merci pour votre confiance',
	},
	disclaimer: {
		html: '<p>Les estimations fournies sont basées sur les attestations GIA pour les diamants de :</p><p>Taille : Très bonne à Excellente</p><p>Symétrie : Très bonne à Excellente</p><p>Fluorescence : nulle à moyenne</p><br><p>Les valeurs réelles peuvent varier.</p><p>Veuillez consulter nos gemmologues certifiés pour des évaluations ponctuelles.</p>',
		precise: 'Veuillez consulter nos gemmologues certifiés pour des évaluations ponctuelles.',
		actual: 'Les valeurs réelles peuvent varier.',
		fluorescence: 'Fluorescence : nulle à moyenne',
		symmetry: 'Symétrie : Très bonne à Excellente',
		cut: 'Taille : Très bonne à Excellente',
		estimates: 'Les estimations fournies sont basées sur les attestations GIA pour les diamants de :',
		disclaimer: 'Avertissement',
	},
	home: {
		guaranteedPayment: 'Paiement garanti et instantané',
		eval: 'Évaluez vos bijoux',
		findBuyers: 'Trouvez des acheteurs potentiels',
		negotiate: 'Négociez la meilleure offre pour vous',
		readOurReviews: 'Lisez nos avis',
		moreAboutShipping: 'En savoir plus sur l’expédition',
		soldFor: 'Vendu pour',
		weTravelAcrossEurope: "Nous pouvons voyager à travers l'Europe pour évaluer vos bijoux, garantissant ainsi commodité et confidentialité.",
		onSiteEvaluation: 'Évaluation sur place',
		receiveHighQualityDiamonds: 'Obtenez facilement pour vos bijoux et diamants les plus précieux, une certification internationale confirmant officiellement leur intérêt et leur valeur.',
		premiumCertification: 'Certification Premium',
		benefitFromPersonalized: "Bénéficiez d'une attention personnalisée et de conseils avec un consultant dédié supervisant vos articles.",
		dedicatedConsultant: 'Conseiller Dédié',
		elevateYourExperience: 'Améliorez votre expérience grâce à nos services personnalisés pour la joaillerie haut de gamme :',
		vipClients: 'Haute Joaillerie',
		getStarted: 'Commençons',
		onceYouApproveTheOffer: "Une fois que vous approuvez l'offre, le paiement est transféré immédiatement sur votre compte. C'est aussi simple que ça.",
		getPaidIn_24Hours: 'Soyez payé en 24 heures',
		theMomentWeCanCheck: 'Dès que nous pouvons vérifier et confirmer aux acheteurs les caractéristiques de votre pièce, les offres des acheteurs potentiels seront collectées et nous vous communiquerons la meilleure.',
		getTheBestOffer: 'Obtenez la Meilleure Offre',
		readMore: 'Lire Plus',
		secureAFullyInsured: "Nous assurerons un service d'expédition rapide et entièrement assuré pour collecter l'article chez vous au moment de votre choix.",
		scheduleShipment: "Planifiez l'Expédition",
		dedicatedSpecialist: 'Un gemmologue spécialisé de notre équipe vous contactera avec une estimation initiale pour vos articles et vous guidera à travers les prochaines étapes.',
		getAValuation: 'Obtenez une évaluation',
		goToForm: 'Aller au Formulaire',
		fewMinutes: 'Il vous suffit de quelques minutes pour télécharger les photos de vos objets de valeur (et un certificat si vous en avez un) et de remplir le formulaire interactif. Nous prendrons le relais.',
		fillASimpleOnlineForm: 'Remplissez un simple formulaire en ligne ',
		auctenticWillHelpYou: 'Auctentic vous aidera à obtenir le meilleur prix, rapidement, en toute sécurité et simplement, en 5 étapes faciles :',
		howItWorks: 'Comment Cela Fonctionne',
		continueTheSubmissionProcess: 'Continuez à remplir le formulaire',
		transformYourHighValueJewels: 'Transformez vos bijoux de grande valeur en une expérience de vente extraordinaire. Remplissez le formulaire ci-dessous et débloquons ensemble leur potentiel.',
		vipCustomers: 'Haute Joaillerie',
		scheduleYourAppointment: 'Prenez rendez-vous',
		customerReviews: 'Avis des Clients',
		paymentMethods: 'Bénéficiez de nos méthodes de paiement sûres et instantanées.',
		safeTransactions: 'Transactions Sécurisées',
		potentialBuyers: 'Détendez-vous pendant que nous présentons vos bijoux à des acheteurs potentiels.',
		idealBuyers: 'Trouver les acheteurs idéaux',
		timelyDeliveries: 'Nous gérons des livraisons sûres et ponctuelles.',
		secureShipment: 'Expédition Sécurisée',
		leaveTheIntricateDealingsToUs: 'Laissez-nous gérer les négociations complexes.',
		expertNegotiation: "Négociation d'Expert",
		weDonTChargeYouADime: "Nous ne vous facturons rien ; notre commission provient uniquement de l'acheteur.",
		noSellerFees: 'Pas de frais de vendeur',
		networkEncompasses: 'Notre réseau comprend plus de 2 000 acheteurs internationaux, nous permettant de sécuriser la meilleure offre pour vos bijoux.',
		globalReach: 'Portée Mondiale',
		specializedGemologist: 'Nos gemmologues spécialisés vous garantissent la véritable valeur de votre précieux.',
		expertAssessment: "Évaluation d'Expert",
		FAQ: 'FAQ',
		withOurOnlineDiamondCalculator: 'Avec notre Diamond Calculator',
		valuateYourDiamond: 'Évaluez Votre Diamant',
		sold: 'Vendu',
		recentlySold: 'Récemment Vendu',
		security: 'Sécurité',
		convenience: 'Confort',
		value: 'Valeur',
		getMore: 'Nos Services',
		sloganline2: ' Sans Obligation de Vente',
		slogan: 'Obtenez la Meilleure Offre,',
		title: 'Vendez Bijoux et Diamants en Ligne',
	},
	productTypes: {
		Watches: 'Montres',
		Earrings: "Boucles d'oreilles",
		Bracelets: 'Bracelets',
		Necklaces: 'Colliers',
		Diamonds: 'Diamants',
		Rings: 'Bagues',
		All: 'Tous',
	},
	footer: {
		sellOnline: 'Vendre en ligne',
		howToPack: 'Comment emballer vos bijoux',
		watches: 'Montres',
		jewellery: 'Bijoux',
		diamonds: 'Diamants',
		gemstones: 'Pierres Précieuses',
		evaluateYourDiamond: 'Évaluez Votre Diamant',
		sellSafely: 'Vendez en Toute Sécurité',
		cookiePolicy: 'Politique de Cookies',
		privacyPolicy: 'Politique de Confidentialité',
		howToTakePhotos: 'Comment prendre des photos',
		faQs: 'FAQs',
		aboutUs: 'À Propos de Nous',
		transparencyAndTrust: 'Transparence et Confiance',
		whatWeAccept: 'Ce que vous pouvez vendre',
		contactUs: 'Contactez-nous',
		blog: 'Blog',
		whySellWithUs: 'Pourquoi vendre avec nous',
		howItWorks: 'Comment Cela Fonctionne',
		general: 'Général',
		franceOfficeAd: '19, Boulevard Malesherbes 75008 Paris',
		franceOffice: 'Filiale Française',
		italyOfficeAd: 'Via Albricci, 9 - 20122 Milan - Tél. +39 02 49751300',
		italyOffice: 'Filiale Italienne',
		belgiumOfficeAd: `À la Bourse des Diamants d'Anvers
Hoveniersstraat 2, bus 550 Bureau 109 - 2018 Anvers`,
		belgiumOffice: 'Bureau en Belgique',
		amsterdamOfficeAd: 'Poortland 66 - 1046 BD Amsterdam TVA : NL863728042B01',
		amsterdamOffice: 'Filiale Hollandaise',
	},
	photos: {
		ruler: "Lorsque vous photographiez votre élément, placez-le à côté d'une règle pour référence d'échelle. Assurez-vous d’avoir une surface propre et optez pour une lumière naturelle et bien éclairée près d’une fenêtre. Évitez le flash pour conserver la profondeur et rapprochez-vous physiquement pour assurer la netteté. Appuyez sur l'écran pour faire la mise au point et expérimentez les angles pour obtenir la photo parfaite qui met efficacement en valeur votre élément.",
		severalPhotos: 'Prenez plusieurs photos : pour prendre de bonnes photos, vous devez souvent en prendre plusieurs. Essayez différents angles, éclairages et réglages pour trouver la photo parfaite qui mettra en valeur votre bijou.',
		focus: "Mise au Point : Assurez-vous que votre bijou reste nette en tapant sur l'écran de votre smartphone pour vous concentrer sur la zone souhaitée.",
		getCloser: "Rapprochez-vous : Zoomer sur votre bijou entraîne souvent une perte de qualité de l'image. Il faudrait mieux s'approcher physiquement du bijou ou utiliser un objectif macro pour des prises de vue détaillées.",
		notUseFlash: "Ne pas utiliser le flash : le flash peut aplatir l'image et cacher des détails complexes et scintiller. Préférez la lumière naturelle pour donner de la profondeur et du réalisme à vos bijoux.",
		naturalLight: "Utilisez la lumière naturelle : l'éclairage du soir n'est pas suffisant pour obtenir des images claires, optez pour des prises de vue diurnes bien éclairées. Pour de meilleurs résultats, photographiez dans une lumière douce et diffuse près d'une fenêtre. Évitez la lumière directe du soleil, car elle peut créer des ombres dures.",
		polishYourGems: "Nettoyez et polissez vos bijoux avant de prendre une photo, assurez-vous que vos bijoux sont exemptes de poussière, d'égratignures ou de toute autre saleté qui pourrait apparaître sur la photo.",
		simpleTips: 'Voici quelques conseils et astuces simples qui vous aideront à obtenir des photos de haute qualité en utilisant simplement votre smartphone.',
		beautifulPhotos: "Prendre de belles photos de vos bijoux n'est pas aussi facile qu'il y paraît ; cela nécessite de la patience et de la pratique pour obtenir la photo parfaite.",
		qualityPhotos: 'Comment prendre des photos de qualité de vos bijoux',
	},
	howWorks: {
		quickandSafePayment: {
			body: "<p>Une fois l'offre acceptée, vous recevrez immédiatement le paiement par virement bancaire. Notre priorité est de vous faire parvenir votre argent de façon rapide et facile. Dans le cas où vous décidez de garder votre article, ne vous inquiétez pas - nous fournissons un renvoi assuré et gratuit à votre domicile dans les 48 heures.</p>",
			title: 'Paiement Rapide et Sécurisé',
		},
		getthebestoffer: {
			body: '<p>Après avoir reçu vos bijoux, nous les préparerons pour la vente en les nettoyant, en les classant et en les photographiant pour attirer des acheteurs potentiels. Nous présenterons vos articles à plus de 2 000 acheteurs professionnels de notre réseau et vous assurerons de recevoir la meilleure offre possible.</p>',
			title: 'Obtenez la Meilleure Offre',
		},
		easyandSecureShipping: {
			body: `<p>Sélectionnez le moment qui vous convient le plus pour envoyer vos articles chez nous, et nous nous occuperons du reste. Notre priorité absolue est d'assurer la prise en charge sûre et ponctuelle de vos articles, avec une couverture d'assurance complète garantie. Nous nous engageons à rendre votre expérience aussi facile que possible tout en assurant la sécurité de vos biens.</p>
			<br>
			<a title="Malca Amit" href="https://www.malca-amit.com/" target="_blank">
				<img src="/malca-amit.svg" alt="malca-amit">
				</a>
			`,
			title: 'Expédition Facile et Sécurisée',
		},
		expertValuation: {
			body: "<p>Les informations que vous fournissez serviront de base à nos gemmologues experts pour évaluer les caractéristiques de votre bijou. L'évaluation initiale, réalisée à travers l'analyse des photos, nous permet d'estimer la valeur d'assurance de la pierre. Ceci est mis en place pour protéger le bijou pendant l'expédition. Nous pourrions vous contacter pour des détails supplémentaires..</p>",
			title: "Évaluation d'Expert",
		},
		ourJourneyBegins: {
			body: "<p>Remplissez notre simple formulaire en ligne pour commencer. C'est facile ; il vous suffit de télécharger des photos et des détails de vos objets de valeur, ainsi que tout certificat que vous pourriez avoir. Notre équipe d'experts examinera les informations que vous avez fournies, et nous répondrons également à toutes les questions que vous pourriez avoir. Commencez dès maintenant et faites le premier pas dans votre démarche.</p>",
			title: 'Notre Aventure Commence',
		},
		subTitle: 'Comment nous vous aidons à obtenir le meilleur prix, rapidement, simplement et en toute sécurité :',
		howItWorks: 'Comment Cela Fonctionne',
	},
	whySell: {
		noStringsAttachedParagraph: "Aucune contrainte : vous pouvez toujours décider de vous désengager, à tout moment, pour n'importe quelle raison, sans aucune obligation de votre part.",
		noStringsAttached: 'Aucun Engagement',
		saferAndQuickerParagraph: "Chaque article que vous envoyez ou livrez personnellement à nos bureaux est soigneusement évalué par nos gemmologues spécialistes, sans frais d'expédition ou d'évaluation. Nous ne ciblons que les acheteurs sélectionnés qui expriment une préférence claire pour les types spécifiques de pierres précieuses et de bijoux que vous souhaitez vendre.",
		saferAndQuicker: 'Plus Sûr et Plus Rapide',
		personalServiceParagraph: 'Nous vous attribuons un spécialiste personnel et dédié qui vous accompagnera tout au long du processus, de bout en bout, veillant à vous tenir informé en temps réel de tout développement à chaque étape du chemin.',
		personalService: 'Service Personnalisé',
		whySellWithUs: 'Pourquoi Vendre Avec Nous ?',
		interestParagraph: "Notre modèle financier repose uniquement sur les revenus provenant de la commission de courtage facturée à l'acheteur, ce qui signifie que nous partageons le même intérêt que vous : obtenir le meilleur prix que le marché a à offrir. Il n'y a aucun coût supplémentaire, ni commissions cachées pour vous.",
		yourInterestIsOurInterest: 'Votre intérêt est notre intérêt',
	},
	whatWeAccept: {
		whatWeAccept: 'Ce que vous pouvez vendre',
	},
	about: {
		FAQ: 'FAQ',
		theTeam: "L'Équipe",
		with_20Years: "Avec plus de 20 ans d'expérience dans le domaine du diamant, Auctentic est votre conseiller idéal en matière de bijoux. Nous mettons à votre disposition un réseau de 2 000 acheteurs de confiance, qui suivent vos ventes avec transparence, professionnalisme et respect. Bienvenue chez Auctentic, où chaque gemme trouve son histoire.",
		aboutUs: 'À Propos de Nous',
		ourOffices: 'Nos bureaux',
	},
	cta: {
		seeMore: 'Lire Plus',
		sellYourNecklace: 'Vendez Votre Collier',
		sellYourGemstone: 'Vendez Votre Pierre Précieuse',
		sellYourWatch: 'Vendez Votre Montre',
		sellYourRing: 'Vendez Votre Bague',
		sellYourEarrings: "Vendez Vos Boucles d'Oreilles",
		sellYourDiamond: 'Vendez Votre Diamant',
		sellYourBracelet: 'Vendez Votre Bracelet',
		getTheBestOffer: 'Obtenez la Meilleure Offre',
		paragraph: 'Découvrez notre service expert et personnalisé pour rendre votre expérience simple et gratifiante',
		startHere: 'Commencez ici',
		getStarted: 'Commençons',
	},
};
