export const es = {
	form: {
		whatIsTheBrandOfYourJewelry: '¿Qué marca es tu joya?',
		continueTitle: '¿Continuar?',
		pendingSubmission: 'Tienes una presentación pendiente. ¿Quieres continuar o reiniciar?',
		restart: 'Reiniciar',
		continue: 'Continuar',
		description: 'Por favor describe tus artículos',
		doYouHaveMoreThanOneItem: '¿Tienes más de un artículo?',
		numberOfDiamonds: 'Número de diamantes',
		caratWeight: 'Peso en quilates',
		personalDetails: 'Detalles personales',
		notsureother: 'No estoy seguro / Otro',
		Round: 'Redondo',
		Princess: 'Princesa',
		Cushion: 'Cojín',
		Marquise: 'Marquesa',
		Emerald: 'Esmeralda',
		Oval: 'Ovalado',
		Pear: 'Pera',
		Heart: 'Corazón',
		Radiant: 'Radiante',
		doYouHaveDocuments: '¿Tienes los documentos?',
		whatIsTheBrandOfYourBracelet: 'Estoy en un archivo vacío',
		howManyDiamondsInYourNecklace: '¿Cuántos diamantes hay en tu collar?',
		howManyDiamondsInYourBracelet: '¿Cuántos diamantes hay en tu pulsera?',
		optional: 'Opcional',
		weReAlmostThere: 'Ya casi estamos ',
		enterYourInfoAndSeeHowMuchYouCanGet: 'Introduce la información necesaria para saber cuánto puedes conseguir ',
		firstName: 'Nombre',
		lastName: 'Apellido',
		email: 'Email',
		pleaseEnterAValidEmail: 'Por favor introduce un correo electrónico válido',
		invalidPhoneNumber: 'Número de teléfono no válido',
		phoneNumber: 'Número de teléfono',
		iAgreeToThe: 'Estoy de acuerdo con los',
		termsAndConditions: 'términos y condiciones',
		excellentAreThereAnyPhotosThatYouCouldShareWithUs: '¡Excelente! ¿Hay alguna foto que puedas compartir con nosotros?',
		no: 'No',
		yes: 'Sí',
		describeYourDiamond: 'Describe tu diamante',
		inAFewWordsDescribeYourJewelry: 'Describe en pocas palabras tus joyas',
		typeAnythingYouWouldLikeUsToKnow: 'Escribe todo lo que quieras que sepamos ',
		uploadJewelryPhotos: 'Sube fotos de joyas',
		enterTheLargestDiamondSCaratWeight: 'Introduce el peso en quilates del diamante más grande',
		whatIsTheCaratWeightOfTheDiamond: '¿Cuál es el peso en quilates del diamante?',
		countTheNumberOfDiamondsOnTheJewlAndEnterBelow: 'Cuenta el número de diamantes en la joya e introdúcelo a continuación',
		whatIsTheTotalNumberOfDiamondsInTheJewl: '¿Cuál es el número total de diamantes en la joya?',
		totalCaratWeightJewl: 'Introduce el peso total en quilates de todos los diamantes de tu joya',
		whatIsTheCaratWeightOfTheDiamonds: '¿Cuál es el peso en quilates de los diamantes?',
		enterHere: 'Introdúcelo aquí',
		whatIsTheBrandOfYourWatch: '¿Cuál es la marca de tu reloj?',
		other: 'Otro',
		doYouHaveTheOriginalBox: '¿Tienes la caja original?',
		whatIsTheCaratWeightOfYourDiamond: '¿Cuál es el peso en quilates de tu diamante?',
		whatIsTheShapeOfYourDiamond: '¿Cuál es la forma de tu diamante?',
		excellentWhatIsTheColorOfYourDiamond: '¡Excelente! ¿Cuál es el color de tu diamante?',
		whatIsTheClarityOfYourDiamond: '¿Cuál es la pureza de tu diamante?',
		greatDoYouHaveACertificateForYourDiamondIfSoWhatKind: '¡Genial! ¿Tienes un certificado de tu diamante? En caso afirmativo, ¿de qué tipo es?',
		whatIsTheTypeOfYourGemstone: '¿De que tipo de piedra preciosa se trata?',
		sapphire: 'Zafiro',
		whatIsTheCaratWeightOfYourGemstone: '¿Cuál es el peso en quilates de tu piedra preciosa?',
		whatIsTheShapeOfYourGemstone: '¿Qué tipo de corte tiene tu piedra preciosa?',
		selectAnOption: 'Selecciona una opción',
		doYouHaveACertificate: '¿Tienes un certificado?',
		notSure: 'No estoy seguro',
		notSureOther: 'No estoy seguro / Otro',
		brooch: 'Broche',
		earring: 'Pendientes',
		ring: 'Anillo',
		bracelet: 'Pulsera',
		necklace: 'Collar',
		watch: 'Reloj',
		diamond: 'Diamante',
		gemstone: 'Piedra preciosa ',
		ruby: 'Rubí',
		radiant: 'Radiant',
		heart: 'Corazón',
		pear: 'Pera',
		oval: 'Oval',
		emerald: 'Esmeralda',
		marquise: 'Marquise',
		cushion: 'Cojín',
		princess: 'Princesa',
		round: 'Redondo',
		addACertificateIfYouHaveOne: 'Sube el certificado si lo tienes',
		addImages: 'Sube las imágenes',
		diamondCertificateUpload: 'Si tienes una imagen del diamante o un certificado, por favor súbelos aquí',
		certificate: 'Certificado',
		WhatShapeOfLargestDiamond: '¿Qué forma tiene el diamante más grande?',
	},
	Other: 'Otro',
	'Multiple Items': 'Varios Artículos',
	'false': 'falso',
	'not sure': 'No estoy seguro',
	Sapphire: 'Zafiro',
	Round: 'Redondo',
	Princess: 'Princesa',
	Cushion: 'Cojín',
	Marquise: 'Marquise',
	Emerald: 'Esmeralda',
	Oval: 'Oval',
	Pear: 'Pera',
	Heart: 'Corazón',
	Radiant: 'Radiant',
	Ruby: 'Rubí',
	Gemstone: 'Piedra preciosa ',
	Diamond: 'Diamante',
	Watch: 'Reloj',
	Necklace: 'Collar',
	Bracelet: 'Pulsera',
	Ring: 'Anillo',
	Earring: 'Pendientes',
	Brooch: 'Broche',
	sell: {
		'Multiple Items': 'Varios Artículos',
		carat_range: 'Quilate',
		brand: 'Marca',
		brand_name: 'Nombre de la marca',
		optional: 'Opcional',
		certificate_type: 'Tipo de certificado',
		gemstone_type: 'Tipo de piedra preciosa',
		number_of_diamonds: 'Número de diamantes',
		watch_box: 'Estuche de reloj',
		certificate_exists: 'Certificado existente',
		watch_brand: 'Marca de reloj',
		skip: 'Saltar',
		carat: 'Quilate',
		ruler: 'Cuando fotografíes tu objeto ponlo junto a una regla como referencia de tamaño. Elige una superficie limpia y bien iluminada, mejor cerca de una ventana. Evita el flash para mantener la profundidad y acércate físicamente para mayor nítidez. Toca la pantalla para enfocar y prueba difrentes ángulos para obtener la foto perfecta que resalte tu joya.',
		priceRange: 'Horquilla de Precio:',
		forCustomers: 'Para clientes con diamantes de 5 quilates o más, por favor contáctanos directamente haciendo clic aquí',
		color: 'Color',
		clarity: 'Pureza',
		weight: 'Peso',
		shape: 'Forma',
		clickToLearn: 'Haz clic para ver algunos consejos sobre como hacer las mejores fotos',
		unsureHow: 'Si no estás seguro de cómo hacer buenas fotos',
		certificate: 'Si tienes un certificado de tus joyas, súbelo aquí',
		addingImages: 'Agregar imágenes permite una estimación más precisa. Fotografía tus joyas junto a una regla con luz natural',
		pleaseInsertYourPhotosHere: 'Sube tus fotos aquí',
		selectTheTypeOfJewelleryYouHave: 'Selecciona el Tipo de Joya que Tienes',
		discoverTheValueOfYourJewels: 'Descubre el Valor de Tus Joyas',
		submit: 'Enviar',
		next: 'Siguiente',
		return: 'Volver',
	},
	recentlySold: {
		clearAll: 'Borrar todo',
		seeMore: 'Ver más',
		subtitle: 'Descubra los últimos artículos que vendimos para nuestros clientes.',
		title: 'vendido recientemente',
	},
	faq: {
		saleProcess: 'Proceso de Venta',
		calculator: 'Calculadora',
		gettingPaid: 'Pago',
		grading: 'Clasificación ',
		serviceCosts: 'Costes del Servicio',
		insurance: 'Seguro',
		shipping: 'Envío',
		pricing: 'Precios',
		general: 'General',
	},
	thankYou: {
		goToDashboard: 'Ir al Tablero',
		backHome: 'Vuelve al Inicio',
		paragraph: 'Nos pondremos en contacto contigo pronto',
		title: 'Gracias por tu confianza',
	},
	contactUs: {
		goToDashboard: 'Ir al Tablero',
		touch: 'Seguiremos en contacto',
		thankYou: '¡Gracias!',
		error: 'Se produjo un error al enviar',
		writeUsAMessage: 'Escríbenos un mensaje',
		netherlands: 'Países Bajos',
		belgium: 'Bélgica',
		spain: 'España',
		france: 'Francia',
		italy: 'Italia',
		phoneEmailWhatsApp: 'Teléfono, Correo Electrónico y WhatsApp',
		bookAMeeting: 'Fija una videollamada',
		bookACall: 'Reserva una llamada con nuestros expertos para obtener más información',
		scheduleAMeeting: 'Programa una Reunión',
		weAlwaysLove: 'Estamos siempre a disposición de nuestros clientes, para garantizar el mejor servicio de la forma más adecuada a cada situación',
		contactUs: 'Contáctanos',
	},
	general: {
		signUp: 'Regístrate',
		events: 'Eventos',
		ourStory: 'Nuestra historia',
		backEvent: 'Volver a Próximos eventos',
		signUpInput: '¿Qué joyas estás interesado en vender?*',
		eventsSubtitle: 'Una lista de eventos de Auctentic donde puedes obtener una estimación y vender tus productos',
		eventsTitle: 'Próximos eventos',
		selling: 'Venta',
		getTheLatest: 'Obtén las últimas novedades sobre joyas, diamantes y la industria',
		ourServices: 'Nuestros servicios',
		message: 'Mensaje',
		phone: 'Teléfono',
		email: 'Correo Electrónico',
		surname: 'Apellido',
		name: 'Nombre',
		send: 'Enviar',
		login: 'Iniciar Sesión',
		howToTakeQualityPhotos: 'Cómo hacer fotos de buena calidad',
		whySellWithUs: 'Por qué vender con nosotros',
		youMayAlsoLike: 'También te puede interesar',
		tableOfContents: 'Índice',
		blog: 'Blog',
		backHome: 'Volver al Inicio',
		somethingEvenBetter: 'pero tenemos algo aún mejor:',
		notExist: 'Esta página no existe,',
		oops: '¡Vaya! No podemos encontrar esta página',
	},
	unqualified: {
		title: 'No podemos vender tu artículo',
		paragraph: `Desafortunadamente, de los datos introducidos se concluye que no somos el canal adecuado para proporcionar una valoración actualizada para el diamante propuesto. Las características indicadas no cumplen los criterios exigidos por el mercado de compradores con el que trabajamos.
Si tienes alguna pregunta, no dudes en ponerte en contacto con nosotros.`,
		questions: 'Si tiene alguna pregunta, no dude en comunicarse con nosotros.',
		differentItem: 'Vender un artículo diferente',
		whatWeSell: 'que puedes vender',
		trust: 'Gracias por tu confianza',
	},
	disclaimer: {
		html: '<p>Las estimaciones facilitadas se basan en certificados GIA para diamantes de:</p><p>Talla: Muy Buena o Excelente</p><p>Simetría: Muy Buena a Excelente</p><p>Fluorescencia: Ninguna a Media</p><br><p>Los valores reales pueden variar.</p><p>Consulte a nuestros gemólogos certificados para obtener evaluaciones precisas.</p>',
		precise: 'Consulte a nuestros gemólogos certificados para obtener evaluaciones precisas.',
		actual: 'Los valores reales pueden variar.',
		fluorescence: 'Fluorescencia: Ninguna a Media',
		symmetry: 'Simetría: Muy Buena a Excelente',
		cut: 'Talla: Muy Buena o Excelente',
		estimates: 'Las estimaciones facilitadas se basan en certificados GIA para diamantes de:',
		disclaimer: 'Descargo de responsabilidad',
	},
	home: {
		guaranteedPayment: 'Pago garantizado e instantáneo',
		eval: 'Evalúa tus joyas',
		findBuyers: 'Encuentra compradores potenciales',
		negotiate: 'Negocia la mejor oferta para ti',
		readOurReviews: 'Lee nuestras reseñas',
		moreAboutShipping: 'Más sobre el envío',
		soldFor: 'Vendido por',
		weTravelAcrossEurope: 'Podemos desplazarnos por toda Europa para valorar tus joyas, garantizando siempre comodidad y privacidad.',
		onSiteEvaluation: 'Evaluación en el lugar',
		receiveHighQualityDiamonds: 'Obtén fácilmente para tus joyas y diamantes más valiosos una certificación internacional que confirme oficialmente su interés y valor.',
		premiumCertification: 'Certificación Premium',
		benefitFromPersonalized: 'Obten una atención personalizada y la asistencia de un consultor dedicado a supervisar tus artículos',
		dedicatedConsultant: 'Consultor Especializado',
		elevateYourExperience: 'Mejore tu experiencia con nuestros servicios personalizados para joyería de alta gama:',
		vipClients: 'Alta Joyería',
		getStarted: 'Empezar',
		onceYouApproveTheOffer: 'Una vez que aceptes la oferta, el pago se realizará inmediatamente a tu cuenta. Así de simple.',
		getPaidIn_24Hours: 'Recibe el pago en 24 horas',
		theMomentWeCanCheck: 'En el momento en que podamos verificar y confirmar a los compradores las características de tu pieza, recopilaremos ofertas de posibles compradores y te comunicaremos la mejor.',
		getTheBestOffer: 'Consigue la Mejor Oferta',
		readMore: 'Leer Más',
		secureAFullyInsured: 'Ofrecemos un servicio de entrega asegurado, rápido y con seguimiento para recoger el artículo en tu domicilio u oficina el día que elijas.',
		scheduleShipment: 'Programa el Envío',
		dedicatedSpecialist: 'Un gemólogo especialista de nuestro equipo, se pondrá en contacto contigo para facilitarte un presupuesto preliminar de tus artículos y guiarte en todos los pasos siguientes.',
		getAValuation: 'Obtén una valoración',
		goToForm: 'Ir al Formulario',
		fewMinutes: 'Todo lo que necesitas son unos minutos para subir fotos de tus objetos de valor (y un certificado si lo tienes) y completar el formulario interactivo. Nosotros nos encargamos del resto.',
		fillASimpleOnlineForm: 'Completa un formulario online simple',
		auctenticWillHelpYou: 'Auctentic te ayudará a obtener el mejor precio, de manera rápida, segura y sencilla, en 5 pasos fáciles:',
		howItWorks: 'Cómo Funciona',
		continueTheSubmissionProcess: 'Continúa rellenando el formulario',
		transformYourHighValueJewels: 'Convierte tus joyas de alto valor en una experiencia de venta extraordinaria. Rellena el siguiente formulario y desbloqueemos juntos todo su potencial.',
		vipCustomers: 'Alta Joyería',
		scheduleYourAppointment: 'Programa tu cita',
		customerReviews: 'Reseñas de Clientes',
		paymentMethods: 'Benefíciate de nuestros métodos de pago seguros e instantáneos.',
		safeTransactions: 'Transacciones Seguras',
		potentialBuyers: 'Relájate mientras proponemos tus joyas a compradores potenciales.',
		idealBuyers: 'Localizar a los compradores ideales',
		timelyDeliveries: 'Gestionamos entregas seguras y puntuales.',
		secureShipment: 'Envío Seguro',
		leaveTheIntricateDealingsToUs: 'Déjanos a nosotros las negociaciones complicadas.',
		expertNegotiation: 'Negociación a cargo de Expertos',
		weDonTChargeYouADime: 'No te cobramos ni un céntimo; nuestra comisión proviene solo del comprador.',
		noSellerFees: 'Sin comisión de venta',
		networkEncompasses: 'Nuestra red abarca más de 2.000 compradores internacionales, permitiéndonos asegurar la mejor oferta para tus joyas.',
		globalReach: 'Alcance Global',
		specializedGemologist: 'Nuestros gemólogos especializados se aseguran de que obtengas el valor real de tu diamante.',
		expertAssessment: 'Evaluación de un Experto',
		FAQ: 'Preguntas Frecuentes',
		withOurOnlineDiamondCalculator: 'Con nuestra calculadora de diamantes en línea',
		valuateYourDiamond: 'Valora Tu Diamante',
		sold: 'Vendido',
		recentlySold: 'Vendido Recientemente',
		security: 'Seguridad',
		convenience: 'Comodidad',
		value: 'Valor',
		getMore: 'Nuestros Servicios',
		sloganline2: ' Sin Obligación de Venta',
		slogan: 'Obtén la Mejor Oferta,',
		title: 'Vende Joyas y Diamantes Online',
	},
	productTypes: {
		Watches: 'Relojes',
		Earrings: 'Aretes',
		Bracelets: 'Pulseras',
		Necklaces: 'Collares',
		Diamonds: 'Diamantes',
		Rings: 'Anillos',
		All: 'Todos',
	},
	footer: {
		sellOnline: 'Vender en línea',
		howToPack: 'Cómo empacar sus joyas',
		watches: 'Relojes',
		jewellery: 'Joyas',
		diamonds: 'Diamantes',
		gemstones: 'Piedras Preciosas',
		evaluateYourDiamond: 'Evalúa Tu Diamante',
		sellSafely: 'Vende de Forma Segura',
		cookiePolicy: 'Política de Cookies',
		privacyPolicy: 'Política de Privacidad',
		howToTakePhotos: 'Cómo hacer las fotos',
		faQs: 'Preguntas frecuentes',
		aboutUs: 'Sobre nosotros ',
		transparencyAndTrust: 'Transparencia y Confianza',
		whatWeAccept: 'Qué puedes vender',
		contactUs: 'Contáctanos',
		blog: 'Blog',
		whySellWithUs: 'Por qué Vender con Nosotros',
		howItWorks: 'Cómo Funciona',
		general: 'General',
		franceOfficeAd: '19, Boulevard Malesherbes 75008 París',
		franceOffice: 'Sede Francesa',
		italyOfficeAd: 'Via Albricci, 9 - 20122 Milán - Tel. +39 02 49751300',
		italyOffice: 'Sede Italiana ',
		belgiumOfficeAd: `En la Bolsa de Diamantes de Amberes
Hoveniersstraat 2, bus 550 Oficina 109 - 2018 Amberes`,
		belgiumOffice: 'Sede Belga',
		amsterdamOfficeAd: 'Poortland 66 - 1046 BD Ámsterdam IVA: NL863728042B01',
		amsterdamOffice: 'Sede Holandesa',
	},
	photos: {
		ruler: 'Cuando fotografíes tu objeto ponlo junto a una regla como referencia de tamaño. Elige una superficie limpia y bien iluminada, mejor cerca de una ventana. Evita el flash para mantener la profundidad y acércate físicamente para mayor nítidez. Toca la pantalla para enfocar y prueba difrentes ángulos para obtener la foto perfecta que resalte tu joya.',
		severalPhotos: 'Haz varias fotos: Para hacer buenas fotos, a menudo tienes que hacer varias tomas. Prueba diferentes ángulos e iluminaciones para encontrar la foto perfecta que realce tu gema.',
		focus: 'Enfócate: Asegúrate de que tu gema permanezca nítida tocando la pantalla de tu teléfono para enfocarte en el área deseada.',
		getCloser: 'Acércate: Hacer zoom resulta una pérdida de calidad de imagen. En su lugar, opta por un acercamiento físico con la gema o usa una lente macro para tomas detalladas.',
		notUseFlash: 'No uses flash: el flash puede aplanar la imagen y ocultar detalles complejos y destellos. Elige la luz natural para dar profundidad y realismo a tus joyas.',
		naturalLight: 'Usa luz natural: la iluminación nocturna no es suficiente para obtener imágenes claras, opta por entornos bien iluminados con luz diurna. Para obtener mejores resultados, dispara con luz suave y difusa cerca de una ventana. Evita la luz solar directa, ya que puede crear sombras antinaturales y excesivas.',
		polishYourGems: 'Limpia y pule tus gemas antes de hacer una foto, asegúrate de que tus gemas estén libres de polvo, cabellos, rasguños o cualquier otra suciedad que pueda aparecer en la foto.',
		simpleTips: 'A continuación, algunos consejos y trucos simples que te ayudarán a obtener fotos de alta calidad usando solo tu teléfono.',
		beautifulPhotos: 'Hacer fotos hermosas de tus gemas no es tan fácil como parece; se necesita paciencia y práctica para obtener la toma perfecta.',
		qualityPhotos: 'Cómo tomar fotos de calidad de tus gemas',
	},
	howWorks: {
		quickandSafePayment: {
			body: '<p>Una vez que aceptes la oferta, recibirás el pago inmediatamente por transferencia bancaria. Nuestra principal prioridad es asegurarnos de que recibas tu dinero de manera rápida y sencilla. En caso de que decidas quedarte con tu artículo, no te preocupes: organizaremos un envío de devolución asegurado y gratuito a la dirección que nos hayas indicado, que recibirás en un plazo de 48 horas.</p>',
			title: 'Pago Rápido y Seguro',
		},
		getthebestoffer: {
			body: '<p>Después de recibir tus joyas, las prepararemos para la venta limpiándolas, clasificándolas y fotografiándolas para atraer a posibles compradores. Presentaremos tus artículos a más de 2.000 compradores profesionales de nuestra red y te comunicaremos la mejor oferta recibida.</p>',
			title: 'Consigue la Mejor Oferta',
		},
		easyandSecureShipping: {
			body: `<p>Elige el mejor momento para enviarnos tus artículos y nosotros nos encargaremos del resto. Nuestra máxima prioridad es garantizar la recogida segura y puntual de tus artículos, con cobertura de seguro completa. Nos esforzamos por hacer que tu experiencia sea lo más fácil posible, ocupándonos de la seguridad integral de tus objetos de valor a 360°.</p>
			<br>
			<a title="Malca Amit" href="https://www.malca-amit.com/" target="_blank">
				<img src="/malca-amit.svg" alt="malca-amit">
				</a>
			`,
			title: 'Envío Fácil y Seguro',
		},
		expertValuation: {
			body: '<p>La información que proporcionas servirá como base para que nuestros gemólogos expertos evalúen las características de tu joya. La valoración inicial, realizada a través del análisis fotográfico, nos permite estimar el valor del seguro de la piedra. Esto se hace para asegurar la joya durante el envío. Podríamos contactarte para detalles adicionales. Estaremos siempre a disposición y te acompañaremos en cada paso.</p>',
			title: 'Valoración de un Experto',
		},
		ourJourneyBegins: {
			body: '<p>Rellena nuestro sencillo formulario en línea para empezar. Es fácil; solo sube fotos y detalles de tus objetos de valor, junto con cualquier certificado que puedas tener. Nuestro equipo de expertos revisará la información que has proporcionado y responderá a cualquier pregunta que puedas tener. Empecemos!',
			title: 'Empecemos ',
		},
		subTitle: 'Cómo podemos ayudarte a obtener el mejor precio, rápidamente y de manera segura y sencilla:',
		howItWorks: 'Cómo Funciona',
	},
	whySell: {
		noStringsAttachedParagraph: 'Sin obligaciones, nunca. Siempre podrás decidir interrumpir el proceso de venta, en cualquier fase, por cualquier motivo y sin ninguna obligación por tu parte.',
		noStringsAttached: 'Sin Compromisos',
		saferAndQuickerParagraph: 'Cada artículo enviado o entregado personalmente en nuestras oficinas es evaluado cuidadosamente por nuestros gemólogos especializados, sin gastos de envío ni de tasación. Solo nos dirigimos a compradores seleccionados que expresan una clara preferencia por los tipos específicos de piedras preciosas y joyas que estas interesado en vender.',
		saferAndQuicker: 'Más Seguro y Rápido',
		personalServiceParagraph: 'Un especialista personal te acompañará durante todo el proceso, de principio a fin, y te mantendrá informado en tiempo real de cualquier novedad.',
		personalService: 'Servicio Personalizado',
		whySellWithUs: '¿Por Qué Vender Con Nosotros?',
		interestParagraph: 'Nuestro modelo financiero se basa en el porcentaje de intermediación que se cobra únicamente al comprador, lo que significa que compartimos el mismo interés que usted: conseguir el mejor precio que el mercado pueda ofrecer. No hay costes adicionales ni comisiones ocultas para usted.',
		yourInterestIsOurInterest: 'Tu interés es nuestro interés',
	},
	whatWeAccept: {
		whatWeAccept: 'Qué puedes vender',
	},
	about: {
		FAQ: 'Preguntas Frecuentes',
		theTeam: 'El Equipo',
		with_20Years: 'Con más de 20 años de experiencia en diamantes, Auctentic es tu asessor ideal de joyería. Ponemos a tu disposición una red de más de 2.000 compradores de confianza, que siguen sus ventas con transparencia, profesionalidad y respeto. Bienvenido a Auctentic, donde cada gema encuentra su historia.',
		aboutUs: 'Sobre nosotros ',
		ourOffices: 'Nuestras oficinas',
	},
	cta: {
		seeMore: 'Ver Más',
		sellYourNecklace: 'Vende Tu Collar',
		sellYourGemstone: 'Vende Tu Piedra Preciosa',
		sellYourWatch: 'Vende Tu Reloj',
		sellYourRing: 'Vende Tu Anillo',
		sellYourEarrings: 'Vende Tus Pendientes',
		sellYourDiamond: 'Vende Tu Diamante',
		sellYourBracelet: 'Vende Tu Pulsera',
		getTheBestOffer: 'Obtén la Mejor Oferta',
		paragraph: 'Descubre nuestro servicio personalizado para que tu viaje sea sencillo y gratificante.',
		startHere: 'Empieza Aquí',
		getStarted: 'Empezar',
	},
};
