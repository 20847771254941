<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

import Modal from '@/components/Modal.vue'
import { faq } from '@/data/faq'
// import { useI18n } from 'vue-i18n';
import { $tdb } from '@/lang'

const faqItems = _computed(() => faq.filter(f => f.showOnHomepage))

let currentItem = _ref<(typeof faqItems)[number]>()
const currentItemExists = _computed({
	get: () => !!currentItem.value,
	set: (val: boolean) => {
		if (!val) currentItem.value = undefined
	}
})

const openModal = (item: (typeof faqItems)[number]) => (currentItem.value = item)
</script>

<template>
	<div class="act_center act_w1030 act_pt-3 act_pb-4">
		<Modal v-model:visible="currentItemExists">
			<div class="act_flex act_gap-1 act_pb-1">
				<h3 class="playfair act_txt24 act_no-marg">
					{{ $tdb(currentItem, 'question') }}
				</h3>
			</div>
			<div v-html="$tdb(currentItem, 'answer')" />
		</Modal>
		<h2 class="playfair act_txt60 act_no-marg act_pb-2 relative">
			{{ $t('home.FAQ') }}
		</h2>
		<div class="wrap-2">
			<!-- v-for="(item, key) in acceptedItems" :key="key" @click="openModal(item)" -->

			<div
				v-for="item in faqItems"
				:id="item._id"
				:key="item._id"
				class="big-light-white-btn hover act_flex act_gap-2"
				@click="openModal(item)"
			>
				<img v-if="item.icon.imageURL" width="54" height="60" :src="item.icon.imageURL" :alt="item.icon.altText">
				<h3 class="normal act_txt18 act_no-marg">
					{{ $tdb(item, 'question') }}
				</h3>
			</div>
		</div>
		<LocalLink class="hover act_pt-4" to="/about-us#faq">
			{{ $t('cta.seeMore') }}
		</LocalLink>
	</div>
</template>

<style>
.wrap-2 {
	/* display: grid; */
	display: flex;
	flex-wrap: wrap;
	/* grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr)); */
	gap: 1rem;
	justify-content: center;
}

.wrap-2 .big-light-white-btn {
	flex: 0 1 500px;
	min-width: 300px;
}
</style>
