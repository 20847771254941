export const en = {
	form: {
		whatIsTheBrandOfYourJewelry: 'What is the brand of your jewelry?',
		continueTitle: 'Continue?',
		pendingSubmission: 'You have a pending submission. Do you want to continue or restart?',
		restart: 'Restart',
		continue: 'Continue',
		description: 'Please describe your items',
		doYouHaveMoreThanOneItem: 'Do you have more than one item?',
		numberOfDiamonds: 'Number of Diamonds',
		caratWeight: 'Carat Weight',
		personalDetails: 'Personal Details',
		notsureother: 'Not Sure / Other',
		Round: 'Round',
		Princess: 'Princess',
		Cushion: 'Cushion',
		Marquise: 'Marquise',
		Emerald: 'Emerald',
		Oval: 'Oval',
		Pear: 'Pear',
		Heart: 'Heart',
		Radiant: 'Radiant',
		doYouHaveDocuments: 'Do you have the documents?',
		whatIsTheBrandOfYourBracelet: 'What is the brand of your bracelet?',
		howManyDiamondsInYourNecklace: 'How many diamonds are in your necklace?',
		howManyDiamondsInYourBracelet: 'How many diamonds are set in your bracelet?',
		optional: 'Optional',
		weReAlmostThere: "We're almost there",
		enterYourInfoAndSeeHowMuchYouCanGet: 'Enter your info to see how much you can get',
		firstName: 'First Name',
		lastName: 'Last Name',
		email: 'Email',
		pleaseEnterAValidEmail: 'Please enter a valid email',
		invalidPhoneNumber: 'Invalid phone number',
		phoneNumber: 'Phone Number',
		iAgreeToThe: 'I agree to the',
		termsAndConditions: 'terms and conditions',
		excellentAreThereAnyPhotosThatYouCouldShareWithUs: 'Excellent! Are there any photos that you could share with us?',
		no: 'No',
		yes: 'Yes',
		describeYourDiamond: 'Describe your diamond',
		inAFewWordsDescribeYourJewelry: 'In a few words, describe your jewelry',
		typeAnythingYouWouldLikeUsToKnow: 'Type anything you would like us to know',
		uploadJewelryPhotos: 'Upload jewelry photos',
		enterTheLargestDiamondSCaratWeight: 'Enter the largest diamond’s carat weight',
		whatIsTheCaratWeightOfTheDiamond: 'What is the carat weight of the diamond?',
		countTheNumberOfDiamondsOnTheJewlAndEnterBelow: 'Count the number of diamonds on the jewl and enter below',
		whatIsTheTotalNumberOfDiamondsInTheJewl: 'What is the total number of diamonds in the jewl?',
		totalCaratWeightJewl: 'Enter the total carat weight of all diamonds in your jewl',
		whatIsTheCaratWeightOfTheDiamonds: 'What is the carat weight of the diamonds?',
		enterHere: 'Enter here',
		whatIsTheBrandOfYourWatch: 'What is the brand of your watch?',
		other: 'Other',
		doYouHaveTheOriginalBox: 'Do you have the original box?',
		whatIsTheCaratWeightOfYourDiamond: 'What is the carat weight of your diamond?',
		whatIsTheShapeOfYourDiamond: 'What is the shape of your diamond?',
		excellentWhatIsTheColorOfYourDiamond: 'Excellent! What is the color of your diamond?',
		whatIsTheClarityOfYourDiamond: 'What is the clarity of your diamond?',
		greatDoYouHaveACertificateForYourDiamondIfSoWhatKind: 'Great! Do you have a certificate for your diamond? If so, what kind?',
		whatIsTheTypeOfYourGemstone: 'What is the type of your gemstone?',
		sapphire: 'Sapphire',
		whatIsTheCaratWeightOfYourGemstone: 'What is the carat weight of your gemstone?',
		whatIsTheShapeOfYourGemstone: 'What is the shape of your gemstone?',
		selectAnOption: 'Select an option',
		doYouHaveACertificate: 'Do you have a certificate?',
		notSure: 'Not Sure',
		notSureOther: 'Not Sure / Other',
		brooch: 'Brooch',
		earring: 'Earring',
		ring: 'Ring',
		bracelet: 'Bracelet',
		necklace: 'Necklace',
		watch: 'Watch',
		diamond: 'Diamond',
		gemstone: 'Gemstone',
		ruby: 'Ruby',
		radiant: 'Radiant',
		heart: 'Heart',
		pear: 'Pear',
		oval: 'Oval',
		emerald: 'Emerald',
		marquise: 'Marquise',
		cushion: 'Cushion',
		princess: 'Princess',
		round: 'Round',
		addACertificateIfYouHaveOne: 'Add A Certificate if you have one',
		addImages: 'Add Images',
		diamondCertificateUpload: 'if you have an image of the diamond, or a certificate please upload them here',
		certificate: 'Certificate',
		WhatShapeOfLargestDiamond: 'What is the shape of the largest diamond?',
	},
	Other: 'Other',
	'Multiple Items': 'Multiple Items',
	'false': 'false',
	'not sure': 'Not Sure',
	Sapphire: 'Sapphire',
	Round: 'Round',
	Princess: 'Princess',
	Cushion: 'Cushion',
	Marquise: 'Marquise',
	Emerald: 'Emerald',
	Oval: 'Oval',
	Pear: 'Pear',
	Heart: 'Heart',
	Radiant: 'Radiant',
	Ruby: 'Ruby',
	Gemstone: 'Gemstone',
	Diamond: 'Diamond',
	Watch: 'Watch',
	Necklace: 'Necklace',
	Bracelet: 'Bracelet',
	Ring: 'Ring',
	Earring: 'Earring',
	Brooch: 'Brooch',
	sell: {
		'Multiple Items': 'Multiple Items',
		carat_range: 'Carat',
		brand: 'Brand',
		brand_name: 'Brand Name',
		optional: 'Optional',
		certificate_type: 'Certificate Type',
		gemstone_type: 'Gemstone Type',
		number_of_diamonds: 'Number of Diamonds',
		watch_box: 'Watch Box',
		certificate_exists: 'Certificate Exists',
		watch_brand: 'Watch Brand',
		skip: 'Skip',
		carat: 'Carat',
		ruler: 'Place your element next to a ruler for scale reference when photographing it. Ensure a clean surface and opt for well-lit, natural daylight near a window. Avoid flash to maintain depth, and get physically closer for clarity. Tap on the screen to focus, and experiment with angles for the perfect shot that highlights your element effectively.',
		priceRange: 'Price Range:',
		forCustomers: 'For VIP customers with diamonds above 5 carats, contact us directly by clicking here',
		color: 'Color',
		clarity: 'Clarity',
		weight: 'Weight',
		shape: 'Shape',
		clickToLearn: 'Click to learn how to take the best photos',
		unsureHow: "If you're unsure how to take good photos",
		certificate: 'If you have a certificate for your valuables, please upload it here',
		addingImages: 'Adding images allows for a more precise estimate. Photograph your jewelry next to a ruler in natural light',
		pleaseInsertYourPhotosHere: 'Please insert your photos here',
		selectTheTypeOfJewelleryYouHave: 'Select the type of jewellery you have',
		discoverTheValueOfYourJewels: 'Discover the Value of Your Jewels',
		submit: 'Submit',
		next: 'Next',
		return: 'Return',
	},
	recentlySold: {
		clearAll: 'Clear All',
		seeMore: 'See More',
		subtitle: 'Discover the latest items we sold for our customers',
		title: 'Recently Sold',
	},
	faq: {
		saleProcess: 'Sale Process',
		calculator: 'Calculator',
		gettingPaid: 'Getting Paid',
		grading: 'Grading',
		serviceCosts: 'Service Costs',
		insurance: 'Insurance',
		shipping: 'Shipping',
		pricing: 'Pricing',
		general: 'General',
	},
	thankYou: {
		goToDashboard: 'Go To Dashboard',
		backHome: 'Back Home',
		paragraph: 'We will contact you soon',
		title: 'Thank you for your trust',
	},
	contactUs: {
		goToDashboard: 'Go to Dashboard',
		touch: 'We will be in touch',
		thankYou: 'Thank you!',
		error: 'An error occurred while sending',
		writeUsAMessage: 'Write us a message',
		netherlands: 'Netherlands',
		belgium: 'Belgium',
		spain: 'Spain',
		france: 'France',
		italy: 'Italy',
		phoneEmailWhatsApp: 'Phone Email & WhatsApp',
		bookAMeeting: 'Book an online meeting',
		bookACall: 'Book a call with our experts to get more information',
		scheduleAMeeting: 'Schedule a Meeting',
		weAlwaysLove: 'We always love to hear from our customers, we do our very best to offer the best service the best way that is comfortable for you.',
		contactUs: 'Contact Us',
	},
	general: {
		signUp: 'Sign Up',
		events: 'Events',
		ourStory: 'Our Story',
		backEvent: 'Back to Upcoming events',
		signUpInput: 'What jewelry are you interested in selling?*',
		eventsSubtitle: 'A list of Auctentic events where you can get an estimate and sell your products',
		eventsTitle: 'Upcoming events',
		selling: 'Sell With Us',
		getTheLatest: 'Get the latest on jewllery, diamonds and the industry',
		ourServices: 'Our Services',
		message: 'Message',
		phone: 'Phone',
		email: 'Email',
		surname: 'Surname',
		name: 'Name',
		send: 'Send',
		login: 'Login',
		howToTakeQualityPhotos: 'How to take quality photos',
		whySellWithUs: 'Why sell with us',
		youMayAlsoLike: 'You May Also Like',
		tableOfContents: 'Table of Contents',
		blog: 'Blog',
		backHome: 'Back Home',
		somethingEvenBetter: "but we've got something even better:",
		notExist: 'This page does not exist,',
		oops: 'Oops! We can’t find this page',
	},
	unqualified: {
		title: "We can't sell your item",
		paragraph: `Unfortunately, from the data entered it appears that we are not the right channel to provide an updated valuation of the proposed diamond. The characteristics indicated do not satisfy the criteria required by the buyer market with which we interface.
For any questions, please do not hesitate to contact us.`,
		questions: 'If you have any questions, feel free to reach out.',
		differentItem: 'Sell a different item',
		whatWeSell: 'What you can sell',
		trust: 'Thank you for your trust',
	},
	disclaimer: {
		html: '<p>Estimates provided are based on GIA certificates for diamonds with:</p><p>Cut: Very Good or Excellent</p><p>Symmetry: Very Good or Excellent</p><p>Fluorescence: None to Medium</p><br><p>Actual values may vary.</p><p>For precise assessments, consult our certified gemologists.</p>',
		precise: 'For precise assessments, consult our certified gemologists.',
		actual: 'Actual values may vary.',
		fluorescence: 'Fluorescence: None to Medium',
		symmetry: 'Symmetry: Very Good or Excellent',
		cut: 'Cut: Very Good or Excellent',
		estimates: 'Estimates provided are based on GIA certificates for diamonds with:',
		disclaimer: 'Disclaimer',
	},
	home: {
		guaranteedPayment: 'Guaranteed & instant payment',
		eval: 'Evaluate your jewelery',
		findBuyers: 'Find potential buyers',
		negotiate: 'Negotiate the best offer for you',
		readOurReviews: 'Read our reviews',
		moreAboutShipping: 'More about shipping',
		soldFor: 'Sold for',
		weTravelAcrossEurope: 'We travel across Europe to evaluate your jewelry at your preferred location, ensuring convenience and privacy.',
		onSiteEvaluation: 'On-Site Evaluation',
		receiveHighQualityDiamonds: 'Receive high-quality diamonds and jewelry certificates affirming authenticity and value.',
		premiumCertification: 'Premium Certification',
		benefitFromPersonalized: 'Benefit from personalized attention and guidance with a dedicated consultant overseeing your items.',
		dedicatedConsultant: 'Dedicated consultant',
		elevateYourExperience: 'Enhance your experience with our tailored services for high-end jewelry:',
		vipClients: 'Top Properties',
		getStarted: 'Get Started',
		onceYouApproveTheOffer: 'Once you approve the offer, the payment is transferred immediately into your account. It’s that simple.',
		getPaidIn_24Hours: 'Get paid in 24 hours',
		theMomentWeCanCheck: 'The moment we can check and confirm to the buyers the characteristics of your piece, offers from potential buyers will be collected and we will communicate you the best one.',
		getTheBestOffer: 'Get the best offer',
		readMore: 'Read More',
		secureAFullyInsured: 'We will secure a fully insured, expedited, shipping service to collect the item from your home the day of your choosing.',
		scheduleShipment: 'Schedule Shipment',
		dedicatedSpecialist: 'A dedicated specialist gemologist from our team will contact you with a preliminary estimate for your items, and guide you through the next steps.',
		getAValuation: 'Get a valuation',
		goToForm: 'Go To Form',
		fewMinutes: 'All you need is a few minutes to upload pictures of your valuables (and a certification if you have one) and fill the interactive form. We will take it from there.',
		fillASimpleOnlineForm: 'Fill a simple online form',
		auctenticWillHelpYou: 'Auctentic will help you get the best price, quickly, safely and simply, in 5 easy steps:',
		howItWorks: 'How it works',
		continueTheSubmissionProcess: 'Continue the submission process',
		transformYourHighValueJewels: "Transform your high-value jewels into an extraordinary selling experience. Complete the form below and let's unlock their full potential together.",
		vipCustomers: 'Top Properties',
		scheduleYourAppointment: 'Schedule your appointment',
		customerReviews: 'Customer Reviews',
		paymentMethods: 'Benefit from our guaranteed and instant payment methods.',
		safeTransactions: 'Safe Transactions',
		potentialBuyers: 'Sit back while we link your jewllery with potential buyers.',
		idealBuyers: 'Locating the ideal buyers',
		timelyDeliveries: 'We manage safe and timely deliveries.',
		secureShipment: 'Secure Shipment',
		leaveTheIntricateDealingsToUs: 'Leave the intricate dealings to us.',
		expertNegotiation: 'Expert Negotiation',
		weDonTChargeYouADime: "We don't charge you a dime; our commission comes only from the buyer.",
		noSellerFees: 'No seller fees',
		networkEncompasses: 'Our network includes over 2,000 international buyers, enabling us to secure the best offer for your jewelry.',
		globalReach: 'Global Reach',
		specializedGemologist: 'Our specialized gemologists ensures you get the true worth of your precioius.',
		expertAssessment: 'Expert Assessment',
		FAQ: 'FAQ',
		withOurOnlineDiamondCalculator: 'With our online diamond calculator',
		valuateYourDiamond: 'Evaluate Your Diamond',
		sold: 'Sold',
		recentlySold: 'Recently Sold',
		security: 'Security',
		convenience: 'Convenience',
		value: 'Value',
		getMore: 'Our Services',
		sloganline2: ' Without Sales Obligation',
		slogan: 'Get the Best Offer,',
		title: 'Sell Jewellery & Diamonds Online',
	},
	productTypes: {
		Watches: 'Watches',
		Earrings: 'Earrings',
		Bracelets: 'Bracelets',
		Necklaces: 'Necklaces',
		Diamonds: 'Diamonds',
		Rings: 'Rings',
		All: 'All',
	},
	footer: {
		sellOnline: 'Sell Online',
		howToPack: 'How to pack your jewelry',
		watches: 'Watches',
		jewellery: 'Jewellery',
		diamonds: 'Diamonds',
		gemstones: 'Gemstones',
		evaluateYourDiamond: 'Evaluate Your Diamond',
		sellSafely: 'Sell Safely',
		cookiePolicy: 'Cookie Policy',
		privacyPolicy: 'Privacy policy',
		howToTakePhotos: 'How to take photos',
		faQs: 'FAQs',
		aboutUs: 'About Us',
		transparencyAndTrust: 'Transparency and Trust',
		whatWeAccept: 'What you can sell',
		contactUs: 'Contact us',
		blog: 'Blog',
		whySellWithUs: 'Why sell with us',
		howItWorks: 'How it works',
		general: 'General',
		franceOfficeAd: '19, Boulevard Malesherbes 75008 Paris',
		franceOffice: 'French Office',
		italyOfficeAd: 'Via Albricci, 9 - 20122 Milan - Tel. +39 02 49751300',
		italyOffice: 'Italian Office',
		belgiumOfficeAd: `At the Antwerp Diamond Exchange
Hoveniersstraat 2, bus 550 Office 109 - 2018 Antwerp`,
		belgiumOffice: 'Belgium Office',
		amsterdamOfficeAd: 'Poortland 66 - 1046 BD Amsterdam VAT: NL863728042B01',
		amsterdamOffice: 'Dutch Office',
	},
	photos: {
		ruler: 'When photographing your element, place it next to a ruler for scale reference. Ensure a clean surface and opt for well-lit, natural daylight near a window. Avoid flash to maintain depth, and get physically closer for clarity. Tap on the screen to focus, and experiment with angles for the perfect shot that highlights your element effectively.',
		severalPhotos: 'Take several photos: to take good photos, you often have to take several ones. Try different angles, lighting and settings to find the perfect photo that will show off your gem.',
		focus: 'Focus: Make sure your gem remains sharp by tapping on the screen of your smartphone to focus on the desired area.',
		getCloser: 'Get closer: Zooming in on your subject often results in a loss of image quality. Instead, opt for a physical close-up with the gem or use a macro lens for detailed shots.',
		notUseFlash: 'Do not use flash: flash can flatten the image and hide complex details and flicker. Favor natural light to give depth and realism to your jewels.',
		naturalLight: 'Use natural light: evening lighting is not sufficient to obtain clear images, opt for well - lit daytime shots.For best results, shoot in soft, diffuse light near a window. Avoid direct sunlight, as it can create harsh shadows...',
		polishYourGems: 'Clean and polish your gems before taking a photo. Make sure your gems are free of dust, hair, scratches or any other dirt that may appear in the photo.',
		simpleTips: 'Below are some simple tips and tricks that will help you get high-quality photos using just your smartphone.',
		beautifulPhotos: 'Taking beautiful photos of your gems is not as easy as it seems; It takes patience and practice to get the perfect shot.',
		qualityPhotos: 'How to take quality photos of your gems',
	},
	howWorks: {
		quickandSafePayment: {
			body: "<p>Once you accept the offer, you will receive payment immediately via bank transfer. Our main priority is ensuring you receive your money quickly and easily. In case you decide to keep your item, don't worry - we provide free insured return shipping to your place within 48 hours.</p>",
			title: 'Quick and Safe Payment',
		},
		getthebestoffer: {
			body: '<p>After receiving your jewelry, we will prepare it for sale by cleaning, grading, and photographing it to attract potential buyers. We will showcase your items to over 2,000 professional buyers in our network and ensure you receive the best possible offer from among them.</p>',
			title: 'Get the best offer',
		},
		easyandSecureShipping: {
			body: `<p>Select a time that is convenient for you to send your items to us, and we will handle the rest. Our top priority is ensuring your items' safe and prompt pickup, with guaranteed full insurance coverage. We are dedicated to making your experience as effortless as possible while providing the security of your valuable possessions.</p>
			<br>
			<a title="Malca Amit" href="https://www.malca-amit.com/" target="_blank">
				<img src="/malca-amit.svg" alt="malca-amit">
				</a>
			`,
			title: 'Easy and Secure Shipping',
		},
		expertValuation: {
			body: '<p>The information you provide will serve as the basis for our expert gemologists to assess the characteristics of your jewel. The initial valuation, conducted through photo analysis, allows us to estimate the insurance value of the stone. This is in place to safeguard the jewel during shipment. We may contact you for additional details. </p>',
			title: 'Expert Evaluation',
		},
		ourJourneyBegins: {
			body: "<p>Fill out our simple online form to get started. It's easy; just upload photos and details of your valuables, along with any certificates you may have. Our team of experts will review the information you provided, and we will also address any questions you may have. Get started now and take the first step on your journey.</p>",
			title: 'Our Journey Begins',
		},
		subTitle: 'How we help you get the best price, quickly, safely and simply:',
		howItWorks: 'How It Works',
	},
	whySell: {
		noStringsAttachedParagraph: 'Finally, you can always decide to opt-out, at any stage, for any reason, with no obligations whatsoever on your part.',
		noStringsAttached: 'No Strings Attached',
		saferAndQuickerParagraph: 'Every item you send or personally deliver to our offices is carefully evaluated by our specialist gemologists, with no cost for the shipping or evaluation. We only target selected buyers who express a clear preference to the specific type of gemstones and jewelry you are interested in selling.',
		saferAndQuicker: 'Safer & Quicker',
		personalServiceParagraph: 'We provide a personal, dedicated specialist to accompany you throughout the whole process, from beginning to end, making sure to keep you informed, in real time, of any development, every step of the way.',
		personalService: 'Personal Service',
		whySellWithUs: 'Why Sell With Us?',
		interestParagraph: 'Our financial model is based on earnings from the brokerage percentage charged to the buyer only, which means we share the same interest as you: get the best price the market has to offer. There are no additional or hidden costs or commissions to you.',
		yourInterestIsOurInterest: 'Your interest is our interest',
	},
	whatWeAccept: {
		whatWeAccept: 'What you can sell',
	},
	about: {
		FAQ: 'FAQ',
		theTeam: 'The Team',
		with_20Years: 'With 20 years of diamond expertise, Auctentic is your perfect jewellery consultant. We connect you to a network of 2,000 trusted buyers, adding flair to your sales with transparency, professionalism, and respect. Welcome to Auctentic, where every gem finds its story.',
		aboutUs: 'About Us',
		ourOffices: 'Our offices',
	},
	cta: {
		seeMore: 'See More',
		sellYourNecklace: 'Sell Your Necklace',
		sellYourGemstone: 'Sell Your Gemstone',
		sellYourWatch: 'Sell Your Watch',
		sellYourRing: 'Sell Your Ring',
		sellYourEarrings: 'Sell Your Earrings',
		sellYourDiamond: 'Sell Your Diamond',
		sellYourBracelet: 'Sell Your Bracelet',
		getTheBestOffer: 'Get the Best Offer',
		paragraph: 'Experience our expert, personalized service to make your journey effortless and rewarding.',
		startHere: 'Start Here',
		getStarted: 'Get Started',
	},
};
