<script setup lang="ts"></script>

<template>
	<div class="act_center act_w1030 act_pt-4">
		<!-- <img class="valuate-img" width="492" src="@/assets/Evaluate-Your-Diamond.png" alt="Valuate Your Diamond"> -->
		<img
			loading="lazy"
			class="valuate-img"
			width="800"
			height="320"
			src="@/assets/Evaluate.webp"
			alt="Valuate Your Diamond"
		>
		<h2 class="playfair act_txt60 act_no-marg act_pb-1 relative">
			{{ $t('home.valuateYourDiamond') }}
		</h2>
		<p class="act_pb-2">
			{{ $t('home.withOurOnlineDiamondCalculator') }}
		</p>
		<LocalLink class="act_btn wide-btn" to="/valuate-diamond">
			{{ $t('cta.startHere') }}
		</LocalLink>
	</div>
</template>

<style>
.valuate-img {
	margin-bottom: -3rem;
	opacity: 0.75;
	object-fit: contain;
}
@media screen and (max-width: 900px) {
	.valuate-img {
		height: 200px;
		margin-bottom: -1rem;
		opacity: 0.75;
	}
}
</style>
