<script setup lang="ts">import { ref as _ref } from 'vue';

import type { FormSubmissionPayload } from '@/api/types'
import { bglForm } from '@/api/form'
import { vipFormSchema } from '@/schemas/vipForm'
import { useStore } from '@/store'
import { BagelForm, Btn, Modal } from '@bagelink/vue'

const open = _ref(false)

let formStatus = _ref('')
const store = useStore()
async function submitForm(formData: FormSubmissionPayload) {
	try {
		await bglForm.postByFormName('VIP Form', formData)
		store.trackEvent({
			form_type: 'vip_form_submit',
			email: formData.email,
			phone: formData.phone,
			with_image: false,
		})
		formStatus.value = 'success'
	} catch {
		formStatus.value = 'err'
	}
}
</script>

<template>
	<Modal v-if="open" v-model:visible="open" width="900px">
		<div class="vip-modal-form">
			<h3
				v-if="formStatus !== 'success'"
				class="playfair act_no-marg act_txt30 act_pb-1 act_center"
			>
				{{ $t('home.vipCustomers') }}
			</h3>
			<p v-if="formStatus !== 'success'" class="act_pb-1 act_center">
				{{ $t('home.transformYourHighValueJewels') }}
			</p>
			<BagelForm class="submit-modal vip-form" :schema="vipFormSchema" @submit="submitForm">
				<template #submit="{ formState }">
					<Btn
						round
						type="submit"
						class="block"
						:loading="formState === 'submitting'"
						value="Submit"
					/>
				</template>
				<template #success>
					<div v-if="formStatus === 'success'" class="act_pt-3 txt-center mb-4">
						<p class="playfair act_no-marg act_txt30 act_pb-2 m_pt-0">
							{{ $t('contactUs.thankYou') }}
						</p>
						<p class="m-0">
							{{ $t('contactUs.touch') }}
						</p>
					</div>
				</template>
			</BagelForm>
			<div v-if="formStatus === 'err'">
				<p class="form-fail">
					{{ $t('contactUs.error') }}
				</p>
			</div>
		</div>
		<img class="vip-modal-img" src="@/assets/vip-bg.webp" alt="VIP">
	</Modal>

	<div class="dark-bg">
		<div class="act_center act_w1030 act_pt-4 act_pb-4">
			<h2 class="playfair act_txt60 act_no-marg act_pb-1 relative">
				<img loading="lazy" width="63" height="52" src="@/assets/vip.svg" alt="Vip">
				{{ $t('home.vipClients') }}
			</h2>
			<p class="act_blue act_w600 balance act_pb-4">
				{{ $t('home.elevateYourExperience') }}
			</p>
			<div class="vip-cards-wrap act_gap-1 act_mb-3">
				<div class="vip-card act_start">
					<img
						loading="lazy"
						width="370"
						height="234"
						src="@/assets/vip-1.webp"
						alt="Dedicated consultant"
					>
					<div class="vip-card-txt">
						<h3 class="act_txt40 playfair act_no-marg">
							{{ $t('home.dedicatedConsultant') }}
						</h3>
						<p class="act_txt16 act_pt-1">
							{{ $t('home.benefitFromPersonalized') }}
						</p>
					</div>
				</div>
				<div class="vip-card act_start">
					<img
						loading="lazy"
						width="370"
						height="234"
						src="@/assets/vip-2.webp"
						alt="Premium Certification"
					>
					<div class="vip-card-txt">
						<h3 class="act_txt40 playfair act_no-marg">
							{{ $t('home.premiumCertification') }}
						</h3>
						<p class="act_txt16 act_pt-1">
							{{ $t('home.receiveHighQualityDiamonds') }}
						</p>
					</div>
				</div>
				<div class="vip-card act_start">
					<img
						loading="lazy"
						width="370"
						height="234"
						src="@/assets/vip-3.webp"
						alt="On-Site Evaluation"
					>
					<div class="vip-card-txt">
						<h3 class="act_txt40 playfair act_no-marg">
							{{ $t('home.onSiteEvaluation') }}
						</h3>
						<p class="act_txt16 act_pt-1">
							{{ $t('home.weTravelAcrossEurope') }}
						</p>
					</div>
				</div>
			</div>
			<button class="act_btn wide-btn" @click="open = !open">
				{{ $t('home.scheduleYourAppointment') }}
			</button>
		</div>
	</div>
</template>

<style>
.vip-modal-form {
	width: 58%;
	--label-color: var(--black);
}
.vip-cards-wrap {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
	justify-content: center;
}

.vip-card-txt {
	padding: 160px 1.5rem 1.5rem;
	background: linear-gradient(0deg, white 50%, rgba(255, 255, 255, 0) 100%);
	position: relative;
}

.vip-card {
	background: white;
	color: var(--black);
	border-radius: 20px;
	overflow: hidden;
	position: relative;
	max-width: 370px;
}

.vip-card img {
	width: 100%;
	object-fit: cover;
	position: absolute;
}

@media screen and (max-width: 900px) {
	.vip-cards-wrap {
		grid-template-columns: unset;
	}
}
</style>
