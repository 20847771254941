import type { BglFormSchemaT, IconType } from '@bagelink/vue'
import { bagelFormUtils } from '@bagelink/vue'

const { txtField, richText, slctField } = bagelFormUtils
interface Block {
	img: string
	label: string
	id: string
	icon: IconType
	schema?: BglFormSchemaT
}

export const blocks: Block[] = [
	{
		img: '/blocks/Hero.svg',
		label: 'Hero',
		id: 'Hero',
		icon: 'star',
		schema: [
			txtField('title', 'Title'),
			txtField('subtitle', 'Subtitle', { multiline: true }),
			{
				id: 'image',
				label: 'Image',
				$el: 'upload',
				attrs: {
					bindkey: 'url',
				},
			},
		],
	},
	{
		img: '/blocks/Header.svg',
		label: 'Header',
		id: 'Header',
		icon: 'title',
		schema: [
			{
				$el: 'tabs',
				id: 'header',
				attrs: {
					tabs: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
				}
			},
			txtField('title', 'Title'),
		],
	},
	{
		img: '/blocks/Btn.svg',
		label: 'Button',
		id: 'PageButton',
		icon: 'touch_app',
		schema: [
			txtField('label', 'Label'),
			txtField('link', 'Link'),
		],
	},
	{
		img: '/blocks/Valuate.svg',
		label: 'Valuate',
		id: 'Valuate',
		icon: 'diamond',
	},
	{
		img: '/blocks/Image.svg',
		label: 'Image',
		id: 'Image',
		icon: 'image',
		schema: [
			txtField('alt', 'Alt Text'),
			{
				id: 'image',
				label: 'Image',
				$el: 'upload',
				attrs: {
					bindkey: 'url',
				},
			},
		],
	},
	{
		img: '/blocks/Line.svg',
		label: 'Line',
		id: 'Line',
		icon: 'horizontal_rule',
	},
	{
		img: '/blocks/Popups.svg',
		label: 'Popups',
		id: 'Popups',
		icon: 'flip_to_front',
	},
	{
		img: '/blocks/RichText.svg',
		label: 'Rich Text',
		id: 'RichText',
		icon: 'text_fields',
		schema: [
			txtField('title', 'Title'),
			richText('content', 'Content'),
		],
	},
	{
		img: '/blocks/TextSideImage.svg',
		label: 'Small image with Text',
		id: 'TextSideImage',
		icon: 'image_search',
		schema: [
			txtField('title', 'Title'),
			txtField('subtitle', 'Subtitle'),
			{
				id: 'image',
				label: 'Image',
				$el: 'upload',
				attrs: {
					bindkey: 'url',
				},
			},
		],
	},
	{
		img: '/blocks/Carousel.svg',
		label: 'Recently Sold',
		id: 'CatalogSlider',
		icon: 'view_carousel',
		schema: [
			slctField('showTypes', 'Show Types', ['Rings', 'Diamonds', 'Necklaces', 'Bracelets', 'Earrings', 'Watches'], { multiselect: true })
		]
	},
	{
		img: '/blocks/ImageCarousel.svg',
		label: 'Image Carousel',
		id: 'ImageCarousel',
		icon: 'web_stories',
	},
	{
		img: '/blocks/Map.svg',
		label: 'Map',
		id: 'Map',
		icon: 'map',
	},
	{
		img: '/blocks/Team.svg',
		label: 'Team',
		id: 'Team',
		icon: 'groups',
	},
	{
		img: '/blocks/Video.svg',
		label: 'Video',
		id: 'Video',
		icon: 'video_library',
	},
]
