// eslint-disable no-null
import type { AxiosResponse } from 'axios'
import type {
	LoginReq,
	Token,
	PasswordRecovery,
	NewPassword,
	SanitizedUserList,
	UserCreate,
	SanitizedUser,
	UserUpdateMe,
	UpdatePassword,
	UserRegister,
	UserUpdate,
	LanguageRead,
	LanguageCreate,
	LanguageCode,
	SingleTranslationRead,
	TranslationRead,
	TranslationCreate,
	FormSubmissionPayload,
	FormSubmissionBase,
	FormSubmission,
	BglFile,
	BglFilePatch,
	AuctionCreate,
	Auction,
	AuctionWithImages,
	BidCeate,
	Bid,
	StorageFile,
	ProductSubmission,
	DealWithProducts,
	Product,
	ProductWithImages,
	PipelineCountry,
	DealWithCustomer,
	Deal,
	ProductEval,
	DealAutomationKey,
	OfferUpdate,
	CourrierRequestWithRelationships,
	CourrierRequest,
	Pipeline,
	PipelineStage,
	NewCustomer,
	Customer,
	CustomerRole,
	CustomerListResponse,
	CustomerWidthRelationships,
	Comment,
	CommentWithReplies,
	HSWebhookPayload,
	HSPipeline,
	HSPipelineStage,
	HSOwner,
	ProjectSettings,
} from './types.d'
import ax from 'axios'

export interface UploadOptions {
	onUploadProgress?: (progressEvent: any) => void
	dirPath?: string
	tags?: string[]
}

export const axios = ax.create({
	baseURL: import.meta.env.VITE_BAGEL_BASE_URL,
	withCredentials: true,
})
export async function checkDb(): Promise<
	AxiosResponse<{ [key: string]: any }>
> {
	return axios.get('/check-db')
}
export const auth = {
	login: async (loginReq: LoginReq): Promise<AxiosResponse<Token>> => {
		return axios.post('/auth/login', loginReq)
	},
	passwordRecovery: async (passwordRecovery: PasswordRecovery) => {
		return axios.post('/auth/password-recovery', passwordRecovery)
	},
	resetPassword: async (newPassword: NewPassword) => {
		return axios.post('/auth/reset-password', newPassword)
	},
}
export const users = {
	getByUserId: async (
		userId: string,
	): Promise<AxiosResponse<SanitizedUser>> => {
		return axios.get(`/users/${userId}`)
	},
	patch: async (
		userId: string,
		userUpdate: UserUpdate,
	): Promise<AxiosResponse<SanitizedUser>> => {
		return axios.patch(`/users/${userId}`, userUpdate)
	},
	delete: async (userId: string) => {
		return axios.delete(`/users/${userId}`)
	},
	get: async (
		limit: number = 100,
		skip?: number,
	): Promise<AxiosResponse<SanitizedUserList>> => {
		return axios.get('/users/', { params: { skip, limit } })
	},
	post: async (
		userCreate: UserCreate,
	): Promise<AxiosResponse<SanitizedUser>> => {
		return axios.post('/users/', userCreate)
	},
	me: {
		get: async (): Promise<AxiosResponse<SanitizedUser>> => {
			return axios.get('/users/me')
		},
		patch: async (
			userUpdateMe: UserUpdateMe,
		): Promise<AxiosResponse<SanitizedUser>> => {
			return axios.patch('/users/me', userUpdateMe)
		},
		password: async (updatePassword: UpdatePassword) => {
			return axios.patch('/users/me/password', updatePassword)
		},
	},
	signup: async (
		userRegister: UserRegister,
	): Promise<AxiosResponse<SanitizedUser>> => {
		return axios.post('/users/signup', userRegister)
	},
}
export const translations = {
	post: async (translations: TranslationCreate[]) => {
		return axios.post('/translations', translations)
	},
	getAll: async (
		namespaces: string[] = [],
	): Promise<AxiosResponse<TranslationRead[]>> => {
		return axios.get('/translations/', { params: { namespaces } })
	},
	get: async (
		languageCode: LanguageCode,
		namespaces: string[] = [],
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.get(`/translations/${languageCode}`, {
			params: { namespaces },
		})
	},
	languages: {
		getByKey: async (
			languageCode: LanguageCode,
			key: string,
			namespaces: string[] = [],
		): Promise<AxiosResponse<SingleTranslationRead>> => {
			return axios.get(`/translations/languages/${languageCode}/${key}`, {
				params: { namespaces },
			})
		},
		getByLanguageCode: async (
			languageCode: LanguageCode,
			namespaces: string[] = [],
		): Promise<AxiosResponse<LanguageRead>> => {
			return axios.get(`/translations/languages/${languageCode}`, {
				params: { namespaces },
			})
		},
		get: async (): Promise<AxiosResponse<LanguageRead[]>> => {
			return axios.get('/translations/languages')
		},
		post: async (
			languageCreate: LanguageCreate,
		): Promise<AxiosResponse<LanguageRead>> => {
			return axios.post('/translations/languages', languageCreate)
		},
	},
	key: async (
		key: string,
		namespaces: string[] = [],
	): Promise<AxiosResponse<TranslationRead>> => {
		return axios.get(`/translations/key/${key}`, { params: { namespaces } })
	},
}
export const form = {
	put: async (
		formId: string,
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmission>> => {
		return axios.put(`/form/${formId}`, formSubmissionPayload)
	},
	getByFormName: async (
		formName: string,
		limit: number = 100,
		skip?: number,
	): Promise<AxiosResponse<{ [key: string]: any }[]>> => {
		return axios.get(`/form/${formName}`, { params: { limit, skip } })
	},
	postByFormName: async (
		formName: string,
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmissionBase>> => {
		return axios.post(`/form/${formName}`, formSubmissionPayload)
	},
	get: async (): Promise<AxiosResponse<string[]>> => {
		return axios.get('/form')
	},
	post: async (
		formSubmissionPayload: FormSubmissionPayload,
	): Promise<AxiosResponse<FormSubmissionBase>> => {
		return axios.post('/form', formSubmissionPayload)
	},
}
export const filesV2 = {
	get: async (pathKey: string): Promise<AxiosResponse<BglFile>> => {
		return axios.get('/files_v2/', { params: { path_key: pathKey } })
	},
	put: async (
		pathKey: string,
		bglFilePatch: BglFilePatch,
	): Promise<AxiosResponse<BglFile>> => {
		return axios.put('/files_v2/', bglFilePatch, {
			params: { path_key: pathKey },
		})
	},
	delete: async (pathKey: string) => {
		return axios.delete('/files_v2/', { params: { path_key: pathKey } })
	},
	upload: async (
		file: File,
		options?: UploadOptions & { dirPath?: string, tags?: string[] },
	): Promise<AxiosResponse<BglFile>> => {
		const formData = new FormData()
		formData.append('upload', file)
		return axios.post('/files_v2/upload', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: options?.onUploadProgress,
			params: { dir_path: options?.dirPath, tags: options?.tags },
		})
	},
	list: async (dirPath?: string): Promise<AxiosResponse<BglFile[]>> => {
		return axios.get('/files_v2/list', { params: { dir_path: dirPath } })
	},
}
export const auction = {
	getByAuctionId: async (
		auctionId: string,
		myBid?: string,
	): Promise<AxiosResponse<AuctionWithImages>> => {
		return axios.get(`/auction/${auctionId}`, { params: { my_bid: myBid } })
	},
	put: async (
		auctionId: string,
		auctionCreate: AuctionCreate,
	): Promise<AxiosResponse<Auction>> => {
		return axios.put(`/auction/${auctionId}`, auctionCreate)
	},
	delete: async (auctionId: string) => {
		return axios.delete(`/auction/${auctionId}`)
	},
	post: async (
		auctionCreate: AuctionCreate,
	): Promise<AxiosResponse<Auction>> => {
		return axios.post('/auction/', auctionCreate)
	},
	get: async (
		filterOptions: string,
	): Promise<AxiosResponse<AuctionWithImages[]>> => {
		return axios.get('/auction/', {
			params: { filter_options: filterOptions },
		})
	},
	bid: async (
		auctionId: string,
		bidCeate: BidCeate,
	): Promise<AxiosResponse<Auction>> => {
		return axios.post(`/auction/${auctionId}/bid`, bidCeate)
	},
	user: async (userId: string): Promise<AxiosResponse<AuctionWithImages[]>> => {
		return axios.get(`/auction/user/${userId}`)
	},
	bids: async (auctionId: string): Promise<AxiosResponse<Bid[]>> => {
		return axios.get(`/auction/${auctionId}/bids`)
	},
	view: async (auctionId: string): Promise<AxiosResponse<Auction>> => {
		return axios.put(`/auction/${auctionId}/view`, {})
	},
}
export const files = {
	getById: async (id: string): Promise<AxiosResponse<StorageFile>> => {
		return axios.get(`/files/${id}`)
	},
	put: async (
		id: string,
		storageFile: StorageFile,
	): Promise<AxiosResponse<StorageFile>> => {
		return axios.put(`/files/${id}`, storageFile)
	},
	delete: async (
		id: string,
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.delete(`/files/${id}`)
	},
	post: async (
		file: File,
		options?: UploadOptions & { dirPath?: string, tags?: string[] },
	): Promise<AxiosResponse<StorageFile>> => {
		const formData = new FormData()
		formData.append('upload', file)
		return axios.post('/files', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: options?.onUploadProgress,
		})
	},
	get: async (topic: string): Promise<AxiosResponse<StorageFile[]>> => {
		return axios.get('/files', { params: { topic } })
	},
	upload: async (
		file: File,
		options?: UploadOptions & { dirPath?: string, tags?: string[] },
	): Promise<AxiosResponse<StorageFile>> => {
		const formData = new FormData()
		formData.append('upload', file)
		return axios.post('/files/upload', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: options?.onUploadProgress,
		})
	},
}
export const staticFiles = {
	get: async (id: string): Promise<AxiosResponse<StorageFile>> => {
		return axios.get(`/static_files/${id}`)
	},
	put: async (
		id: string,
		storageFile: StorageFile,
	): Promise<AxiosResponse<StorageFile>> => {
		return axios.put(`/static_files/${id}`, storageFile)
	},
	delete: async (
		id: string,
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.delete(`/static_files/${id}`)
	},
	post: async (
		file: File,
		options?: UploadOptions & { dirPath?: string, tags?: string[] },
	): Promise<AxiosResponse<StorageFile>> => {
		const formData = new FormData()
		formData.append('upload', file)
		return axios.post('/static_files', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: options?.onUploadProgress,
			params: { dir_path: options?.dirPath, tags: options?.tags },
		})
	},
	upload: async (
		file: File,
		options?: UploadOptions & { dirPath?: string, tags?: string[] },
	): Promise<AxiosResponse<StorageFile>> => {
		const formData = new FormData()
		formData.append('upload', file)
		return axios.post('/static_files/upload', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: options?.onUploadProgress,
			params: { dir_path: options?.dirPath, tags: options?.tags },
		})
	},
}
export const sales = {
	newDeal: async (
		customerId: string,
		productSubmission: ProductSubmission,
	): Promise<AxiosResponse<DealWithProducts>> => {
		return axios.post(`/sales/new_deal/${customerId}`, productSubmission)
	},
	deals: async (
		customerId: string,
	): Promise<AxiosResponse<DealWithProducts[]>> => {
		return axios.get(`/sales/deals/${customerId}`)
	},
	image: async (
		imageId: string,
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.delete(`/sales/image/${imageId}`)
	},
	product: {
		get: async (): Promise<AxiosResponse<Product[]>> => {
			return axios.get('/sales/product/')
		},
		put: async (productDict: {
			[key: string]: any
		}): Promise<AxiosResponse<ProductWithImages>> => {
			return axios.put('/sales/product/', productDict)
		},
	},
	deal: {
		getByDealId: async (
			dealId: string,
		): Promise<AxiosResponse<DealWithCustomer>> => {
			return axios.get(`/sales/deal/${dealId}`)
		},
		delete: async (
			dealId: string,
		): Promise<AxiosResponse<{ [key: string]: any }>> => {
			return axios.delete(`/sales/deal/${dealId}`)
		},
		put: async (
			dealId: string,
			dealDict: { [key: string]: any },
		): Promise<AxiosResponse<Deal>> => {
			return axios.put(`/sales/deal/${dealId}`, dealDict)
		},
		get: async (
			page: number = 1,
			country?: PipelineCountry,
			stageId?: string,
			searchTerm?: string,
		): Promise<AxiosResponse<DealWithCustomer[]>> => {
			return axios.get('/sales/deal', {
				params: { country, stage_id: stageId, search_term: searchTerm, page },
			})
		},
		eval: async (
			dealId: string,
			evalDict: { [key: string]: any },
		): Promise<AxiosResponse<Deal>> => {
			return axios.post(`/sales/deal/${dealId}/eval`, evalDict)
		},
		stage: async (
			dealId: string,
			stageKey: DealAutomationKey,
		): Promise<AxiosResponse<Deal>> => {
			return axios.put(
				`/sales/deal/${dealId}/stage`,
				{},
				{ params: { stage_key: stageKey } },
			)
		},
	},
	eval: {
		put: async (
			evalId: string,
			evalDict: { [key: string]: any },
		): Promise<AxiosResponse<ProductEval>> => {
			return axios.put(`/sales/eval/${evalId}`, evalDict)
		},
		delete: async (
			evalId: string,
		): Promise<AxiosResponse<{ [key: string]: any }>> => {
			return axios.delete(`/sales/eval/${evalId}`)
		},
	},
	offer: async (
		offerId: string,
		offerUpdate: OfferUpdate,
	): Promise<AxiosResponse<ProductEval>> => {
		return axios.put(`/sales/offer/${offerId}`, offerUpdate)
	},
	courrierRequest: {
		getByCourrierRequestId: async (
			courrierRequestId: string,
		): Promise<AxiosResponse<CourrierRequest>> => {
			return axios.get(`/sales/courrier_request/${courrierRequestId}`)
		},
		put: async (
			courrierRequestId: string,
			updateDict: { [key: string]: any },
		): Promise<AxiosResponse<CourrierRequest>> => {
			return axios.put(
				`/sales/courrier_request/${courrierRequestId}`,
				updateDict,
			)
		},
		get: async (): Promise<
			AxiosResponse<CourrierRequestWithRelationships[]>
		> => {
			return axios.get('/sales/courrier_request')
		},
		post: async (courrierRequestDict: {
			[key: string]: any
		}): Promise<AxiosResponse<CourrierRequest>> => {
			return axios.post('/sales/courrier_request', courrierRequestDict)
		},
	},
	automationKeys: async (): Promise<AxiosResponse<string[]>> => {
		return axios.get('/sales/automation_keys')
	},
	pipeline: {
		getByPipelineId: async (
			pipelineId: string,
		): Promise<AxiosResponse<Pipeline>> => {
			return axios.get(`/sales/pipeline/${pipelineId}`)
		},
		put: async (
			pipelineId: string,
			updateDict: { [key: string]: any },
		): Promise<AxiosResponse<Pipeline>> => {
			return axios.put(`/sales/pipeline/${pipelineId}`, updateDict)
		},
		get: async (): Promise<AxiosResponse<Pipeline[]>> => {
			return axios.get('/sales/pipeline')
		},
	},
	pipelineCountry: async (): Promise<AxiosResponse<PipelineCountry[]>> => {
		return axios.get('/sales/pipeline_country')
	},
	stage: {
		getByStageId: async (
			stageId: string,
		): Promise<AxiosResponse<PipelineStage>> => {
			return axios.get(`/sales/stage/${stageId}`)
		},
		put: async (
			stageId: string,
			updateDict: { [key: string]: any },
		): Promise<AxiosResponse<PipelineStage>> => {
			return axios.put(`/sales/stage/${stageId}`, updateDict)
		},
		get: async (): Promise<AxiosResponse<PipelineStage[]>> => {
			return axios.get('/sales/stage')
		},
		post: async (stageDict: {
			[key: string]: any
		}): Promise<AxiosResponse<PipelineStage>> => {
			return axios.post('/sales/stage', stageDict)
		},
	},
}
export const customer = {
	getByCustomerId: async (
		customerId: string,
	): Promise<AxiosResponse<CustomerWidthRelationships>> => {
		return axios.get(`/customer/${customerId}`)
	},
	put: async (
		customerId: string,
		newCustomer: NewCustomer,
	): Promise<AxiosResponse<CustomerWidthRelationships>> => {
		return axios.put(`/customer/${customerId}`, newCustomer)
	},
	post: async (newCustomer: NewCustomer): Promise<AxiosResponse<Customer>> => {
		return axios.post('/customer/', newCustomer)
	},
	get: async (
		role: CustomerRole = 'Seller',
		page: number = 1,
		search?: string,
	): Promise<AxiosResponse<CustomerListResponse[]>> => {
		return axios.get('/customer/', { params: { role, page, search } })
	},
	otp: async (
		otpId: string,
		emailOrPhone?: string,
	): Promise<AxiosResponse<CustomerWidthRelationships>> => {
		return axios.get(`/customer/otp/${otpId}`, {
			params: { email_or_phone: emailOrPhone },
		})
	},
}
export const comment = {
	put: async (
		commentId: string,
		comment: Comment,
	): Promise<AxiosResponse<CommentWithReplies[]>> => {
		return axios.put(`/comment/${commentId}`, comment)
	},
	post: async (
		refId: string,
		comment: Comment,
	): Promise<AxiosResponse<CommentWithReplies[]>> => {
		return axios.post(`/comment/${refId}`, comment)
	},
	get: async (refId: string): Promise<AxiosResponse<CommentWithReplies[]>> => {
		return axios.get(`/comment/${refId}`)
	},
}
export const hubspot = {
	webhook: async (
		payload: HSWebhookPayload[],
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.post('/hubspot/webhook', payload)
	},
	pipeline: {
		getByPipelineId: async (
			pipelineId: string,
		): Promise<AxiosResponse<HSPipeline>> => {
			return axios.get(`/hubspot/pipeline/${pipelineId}`)
		},
		put: async (
			pipelineId: string,
			updateDict: { [key: string]: any },
		): Promise<AxiosResponse<HSPipeline>> => {
			return axios.put(`/hubspot/pipeline/${pipelineId}`, updateDict)
		},
		get: async (): Promise<AxiosResponse<HSPipeline[]>> => {
			return axios.get('/hubspot/pipeline')
		},
		post: async (
			hSPipeline: HSPipeline,
		): Promise<AxiosResponse<HSPipeline>> => {
			return axios.post('/hubspot/pipeline', hSPipeline)
		},
	},
	customers: async () => {
		return axios.get('/hubspot/customers')
	},
	updatePipelines: async (): Promise<AxiosResponse<HSPipeline[]>> => {
		return axios.get('/hubspot/update_pipelines')
	},
	stage: {
		get: async (): Promise<AxiosResponse<HSPipelineStage[]>> => {
			return axios.get('/hubspot/stage')
		},
		post: async (
			hSPipelineStage: HSPipelineStage,
		): Promise<AxiosResponse<HSPipelineStage>> => {
			return axios.post('/hubspot/stage', hSPipelineStage)
		},
		put: async (
			stageId: string,
			updateDict: { [key: string]: any },
		): Promise<AxiosResponse<HSPipelineStage>> => {
			return axios.put(`/hubspot/stage/${stageId}`, updateDict)
		},
	},
	owners: async (): Promise<AxiosResponse<HSOwner[]>> => {
		return axios.get('/hubspot/owners')
	},
	deals: async () => {
		return axios.get('/hubspot/deals')
	},
}
export const projectSettings = {
	delete: async (
		key: string,
	): Promise<AxiosResponse<{ [key: string]: any }>> => {
		return axios.delete(`/project_settings/${key}`)
	},
	getById: async (id: string): Promise<AxiosResponse<ProjectSettings>> => {
		return axios.get(`/project_settings/${id}`)
	},
	put: async (id: string, projectSettings: ProjectSettings) => {
		return axios.put(`/project_settings/${id}`, projectSettings)
	},
	get: async (): Promise<AxiosResponse<ProjectSettings[]>> => {
		return axios.get('/project_settings')
	},
	post: async (projectSettings: ProjectSettings) => {
		return axios.post('/project_settings', projectSettings)
	},
}
