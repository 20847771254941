<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

import { localizer } from '@/lang'
import { useStore } from '@/store'
import { Btn } from '@bagelink/vue'

const props = defineProps<{
	formStep: string
	title: string
	subtitle: string
	modelValue?: any
	onSkip?: () => void
}>()

const store = useStore()

async function submitForm() {
	await store.submitForm()
	const { form } = store
	const { email: e, phone: p, item, first_name, last_name } = form

	const funnel_name = item?.toLowerCase().replace(/\s/g, '_')
	const event = {
		form_name: `${funnel_name}_funnel`,
		form_type: 'main_form_submit',
		form_step: 'thank_you_step',
		with_image: form.images?.length > 0,
	}
	store.trackEvent(event)

	const path = localizer('thank-you')
	const query: Record<string, any> = {
		withImage: event.with_image,
		e,
		p,
		fn: first_name,
		ln: last_name
	}
	window.location.href = `${path}?${new URLSearchParams(query).toString()}`
}

async function nextStep() {
	if (props.formStep !== 'registration') return store.nextStep()
	const form: HTMLFormElement | null = document.querySelector('.frm-personal-details form')
	console.log(form)
	if (!form) return false

	const isValid = form.reportValidity()
	if (isValid) {
		store.nextStep()
		await store.personalDetails()
	}
}

const hasValue = _computed(() => {
	if (props.formStep === 'carat' || props.formStep === 'carat_range') {
		return !!store.form.carat || !!store.form.carat_range
	}
	if (props.formStep === 'registration') {
		return store.form.first_name && store.form.last_name && store.form.email && store.form.phone
	}
	if (props.formStep === 'multi_item') {
		return props.modelValue !== undefined
	}
	return !!store.form[props.formStep]
})

const disabled = _ref(false)
</script>

<template>
	<div>
		<div class="act_center act_w900" :form-step="formStep">
			<p class="playfair act_txt30 pretty form-title">
				{{ title }}
			</p>
			<p v-if="subtitle" class="py-1 act_txt14">
				{{ subtitle }}
			</p>
			<div class="wiz-options-wrap flex-desktop act_gap-2 justify-content-center step-slot">
				<slot />
			</div>
			<div class="act_flex gap-1 justify-content-center act_pt-2 align-center act_pb-4">
		
				<Btn
					@click="store.prevStep"	icon="arrow_back" color="white"	flat round
					class="px-1"
					:value="$t('sell.return')"
				/>
				<Btn
					v-if="formStep !== 'submit_photos'"
					:disabled="!hasValue"
					@click="nextStep"
					round
					:value="$t('sell.next')"
				/>
				<Btn
					v-else-if="formStep === 'submit_photos'"
					:disabled="disabled"
					@click="submitForm"
					:loading="store.submitting"
					round
					:value="$t('sell.submit')"
				/>
				<button v-if="onSkip && disabled" class="act_btn" @click="nextStep">
					{{ $t('sell.skip') }}
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.w600 {
	max-width: 600px !important;
}
.col .step-slot {
	flex-direction: column;
	flex-wrap: wrap;
}
img.act_loading {
	margin-top: -10px;
	margin-bottom: -10px;
	margin-left: 14px;
	margin-right: auto;
	text-align: center;
}
@media screen and (max-width: 900px) {
	.act_flex.act_gap-2.justify-content-center.act_pt-4.align-center.act_pb-4 {
		padding-top: 1rem;
	}
}
</style>
