<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { initBglForm } from './formUtils'

const gtm = useGtm()

function enableGtm() {
	gtm?.enable(true)
	document.removeEventListener('mousemove', enableGtm)
	document.removeEventListener('touchstart', enableGtm)
}
const route = useRoute()

onMounted(() => {
	if (route.path.match('thank-you')) { enableGtm() }
	else {
		document.addEventListener('mousemove', enableGtm)
		document.addEventListener('touchstart', enableGtm)
	}
	setTimeout(initBglForm, 100)
})
</script>

<template>
	<ModalContainer />
	<router-view />
</template>
